import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { Card, CardContent, Button} from "@material-ui/core";
import { TextBox, Password, Check, Picker } from "../FormFields";
import withStyles from "@material-ui/core/styles/withStyles";
import ImperianIcon from "../../Icons/pie-transparent.png";
import buildStyles from "../SignIn/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

const styles = theme => buildStyles(theme);

const INITIAL_STATE = {
  username: "",
  email: "",
  password: "",
  password_confirmation: "",
  error: null,
  activeStep: 0
};

const NewTextBox = props => {
  return props.schema.map(function(item, key) {
    if (item.step === props.activeStep) {
      switch (item.type) {
        case "textbox":
          return (
            <TextBox
              key={key}
              fieldLabel={item.title}
              fieldName={item.name}
              handleChange={props.handleChange}
            />
          );
        case "bool":
          return (
            <div>
            <Check tooltip={item.tooltip} key={key} label={item.title} fieldName={item.name} handleClick={props.handleChange} />
              <br />
            </div>
          );
        case "date":
          return <Picker key={key} label={item.title} fieldName={item.name} />;
        case "password":
          return (
            <Password
              key={key}
              handleChange={props.handleChange}
            />
          );
        default:
          return true;
      }
    } else {
      return true
    }
  });
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { activeStep } = this.state;
    const { classes, error } = this.props;
    const steps = [
      { title: "Registration" },
      { title: "Membership Tracks" },
      { title: "Technology" },
      { title: "Solution Segments" },
      { title: "Motivation" }
    ];
    this.schema = [
      {
        step: 0,
        name: "username",
        title: "User Name",
        type: "textbox"
      },
      {
        step: 0,
        name: "email",
        title: "Email",
        type: "textbox"
      },
      {
        step: 0,
        name: "password",
        title: "Password",
        type: "password"
      },
      {
        step: 1,
        name: "innovation_partner",
        title: "Innovation Partner",
        type: "bool",
        tooltip: "Individuals and entities engaged in technology finance including sector focused financial institutions, VC firms, hedge funds, private equity firms, home offices and angels. (Must pass accredited investor check)"
      },
      {
        step: 1,
        name: "talent_partner",
        title: "Talent Partner",
        type: "bool",
        tooltip: "Individuals, teams and firms, new and established, engaged in developing software products, services, tools, processes and methodologies."
      },
      {
        step: 1,
        name: "dual_track",
        title: "Dual Track",
        type: "bool",
        tooltip: "Angels, entities, individuals, teams and firms who are engaged in both technology finance and software development activities. (Must pass accredited investor check)"
      },
      {
        step: 1,
        name: "institution",
        title: "Institution",
        type: "bool",
        tooltip: "Regulators of all types, federal agencies and their employees,states, counties, municipalities, political subdivisions and instrumentalities, universities and colleges and other not for profit entities engaged in serving their respective client populations."
      },
      {
        step: 1,
        name: "vendor",
        title: "Vendor",
        type: "bool",
        tooltip: "Individuals and entities intending to sell products, services, software, tools, content, processes and methodologies to members and participants on the platform."
      },
      {
        step: 2,
        name: "artificial_intelligence",
        title: "Artificial Intelligence",
        type: "bool",
      },
      {
        step: 2,
        name: "big_data",
        title: "Big Data",
        type: "bool"
      },
      {
        step: 2,
        name: "blockchain",
        title: "Blockchain",
        type: "bool"
      },
      {
        step: 2,
        name: "encrypted_digital_assets",
        title: "Encrypted Digital Assets",
        type: "bool"
      },
      {
        step: 2,
        name: "intelligent_automation",
        title: "Intelligent Automation",
        type: "bool"
      },
      {
        step: 2,
        name: "internet_of_things",
        title: "Internet of Things",
        type: "bool"
      },
      {
        step: 2,
        name: "machine_learning",
        title: "Machine Learning",
        type: "bool"
      },
      {
        step: 2,
        name: "tokenization",
        title: "Tokenization",
        type: "bool"
      },
      {
        step: 2,
        name: "robotics",
        title: "Robotics",
        type: "bool"
      },
      {
        step: 2,
        name: "other_technology",
        title: "Other",
        type: "textbox"
      },
      {
        step: 3,
        name: "healthcare",
        title: "Healthcare",
        type: "bool",
        tooltip: "hospital, medical, health, nursery care, nursing care, clinical, ambulance, laboratory, and related services and facilities."
      },
      {
        step: 3,
        name: "housing",
        title: "Housing",
        type: "bool",
        tooltip: "housing, mortgage finance, and related services, activities, facilities, and properties."
      },
      {
        step: 3,
        name: "corrections",
        title: "Corrections",
        type: "bool",
        tooltip: "penitentiary, rehabilitation, incarceration and other correctional services and facilities."
      },
      {
        step: 3,
        name: "transportation",
        title: "Transportation",
        type: "bool",
        tooltip: "mass transit, commuting and transportation and parking services, equipment and facilities."
      },
      {
        step: 3,
        name: "civic_and_cultural",
        title: "Civic and Cultural",
        type: "bool",
        tooltip: "cultural and civic facilities, services and activities."
      },
      {
        step: 3,
        name: "development",
        title: "Development",
        type: "bool",
        tooltip: "community development and redevelopment facilities and activities."
      },
      {
        step: 3,
        name: "energy",
        title: "Energy",
        type: "bool",
        tooltip: "gas, electric, petroleum, coal and other energy collection, recovery, generation, storage, transportation and distribution facilities and activities."
      },
      {
        step: 3,
        name: "economic_development",
        title: "Economic Development",
        type: "bool",
        tooltip: "industrial, manufacturing and other economic development facilities and activities."
      },
      {
        step: 3,
        name: "environment",
        title: "Environment",
        type: "bool",
        tooltip: "antipollution and air, water, groundand subsurface pollution abatement and control facilities and activities"
      },
      {
        step: 3,
        name: "ports",
        title: "Ports",
        type: "bool",
        tooltip: "airport and water port and related facilities, services and activities."
      },
      {
        step: 3,
        name: "government",
        title: "Government",
        type: "bool",
        tooltip: "facilities, property and equipment of any nature for the use or occupancy of the state or the United States, or any agencies or instrumentalities thereof or of any governmental units in the state"
      },
      {
        step: 4,
        name: "social",
        title: "Social",
        type: "bool",
        tooltip: "I want to participate in making a better, more inclusive society."
      },
      {
        step: 4,
        name: "technology",
        title: "Technology",
        type: "bool",
        tooltip: "I want to participate in advancing technology."
      },
      {
        step: 4,
        name: "education",
        title: "Education",
        type: "bool",
        tooltip: "I want to participate in improving quality and expanding access to education for myself and others."
      },
      {
        step: 4,
        name: "environment",
        title: "Environment",
        type: "bool",
        tooltip: "I want to participate in improving our environment. "
      },
      {
        step: 4,
        name: "economic",
        title: "Economic",
        type: "bool",
        tooltip: "I want to participate in improving our economic system. "
      },
      {
        step: 4,
        name: "political",
        title: "Political",
        type: "bool",
        tooltip: "I want to participate in improving government, and our political system"
      },
      {
        step: 4,
        name: "profit",
        title: "Profit",
        type: "bool",
        tooltip: "I want to make money"
      },
      {
        step: 4,
        name: "sanity",
        title: "Sanity",
        type: "bool",
        tooltip: "Stop the madness!"
      },
      {
        step: 4,
        name: "other",
        title: "Other",
        type: "textbox"
      }
    ];

    this.handleNext = () => {
      this.setState({
        activeStep: this.state.activeStep + 1
      });
    };

    this.handleBack = () => {
      this.setState({
        activeStep: this.state.activeStep - 1
      });
    };

    return (
      <main className={classes.main}>
        <Card className={classes.paper}>
          <CardContent className={classes.form}>
            <img
              src={ImperianIcon}
              alt="Imperian Logo"
              className={classes.responsive}
            />
            {error && <p>{error.message}</p>}

            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{label.title}</StepLabel>
                    <StepContent>
                      <NewTextBox
                        schema={this.schema}
                        handleChange={this.props.handleChange}
                        activeStep={index}
                      />
                      <br />
                      <br />
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          {activeStep === steps.length - 1 ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.submit}
                              onClick={this.props.handleCreate}
                            >
                              Join Pie
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.handleNext}
                              className={classes.submit}
                            >
                              Next
                            </Button>
                          )}
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </CardContent>
        </Card>
      </main>
    );
  }
}

export const SignUpLink = () => (
  <p>
    Don't have an account?{" "}
    <Link
      style={{
        textDecoration: "none",
        // color: "#009688"
        color: "#7986cb"
      }}
      to={ROUTES.SIGN_UP}
    >
      Sign Up
    </Link>
  </p>
);

export default withStyles(styles)(withRouter(withFirebase(SignUpForm)));
