import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { PasswordForgetLink } from "../PasswordForget";
import { SignUpLink } from "../SignUp";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Card,
  CardContent,
  CardActions,
  Popover,
  Typography,
  Grid
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ImperianIcon from "../../Icons/pie-transparent.png";
import buildStyles from "./styles";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  failedAuth: null
};

const styles = theme => buildStyles(theme);

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const { classes } = this.props;
    const isInvalid = password === "" || email === "";
    const { anchorEl } = this.state;
    const open = Boolean(this.state.anchorEl);

    return (
      <main className={classes.main}>
        <form className={classes.form} onSubmit={this.onSubmit}>
          <Card className={classes.paper}>
            <CardContent>
              <img
                src={ImperianIcon}
                alt='Imperian Logo'
                className={classes.responsive}
              />
              {error && <p>{error.message}</p>}
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='email'>Email Address</InputLabel>
                <Input
                  name='email'
                  value={email}
                  onChange={this.onChange}
                  type='text'
                  placeholder='Email Address'
                />
              </FormControl>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='password' onChange={this.onChange}>
                  Password
                </InputLabel>
                <Input
                  name='password'
                  type='password'
                  id='password'
                  defaultValue=''
                  autoComplete='off'
                  onChange={this.onChange}
                />
              </FormControl>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    value='remember'
                    color='primary'
                    onChange={this.onChange}
                  />
                }
                label='Remember me'
              /> */}
            </CardContent>
            <CardActions>
              <Grid container alignItems='center' justify='center'>
                <Grid item>
                  <Button
                    type='submit'
                    variant='outlined'
                    color='primary'
                    disabled={isInvalid}
                    className='submit'>
                    Sign in
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            <br /><br />
            <PasswordForgetLink/>
            <SignUpLink handleChange={this.props.handleChange}/>
          </Card>
          <Popover
            id='simple-popper'
            isOpen={this.state.failedAuth}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}>
            <Typography variant='caption' className='typography'>
              {error && <p>{error.message}</p>}
            </Typography>
          </Popover>
        </form>
      </main>
    );
  }
}

export default withStyles(styles)(withRouter(withFirebase(SignInPage)));
