import React from "react";
import { Grid } from "@material-ui/core";
import DxGrid from "../DxGrid";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Settings from "../../constants/settings"
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { compose } from "recompose";
import * as ROLES from "../../constants/roles";


class WidgetGrid extends React.Component {
  state = {
    isLoading: true,
    data: [],
    error: null,
    columns: [],
    index: [],
    users: [],
    defaultColumnWidths: []
  };

  componentDidMount() {
    this.index();
  }

  index() {
    this.props.firebase.widgets().on("value", snapshot => {
      const widgetsObject = snapshot.val();
      if(widgetsObject){
        let i = null
        let j = null
        for(i in widgetsObject){
          widgetsObject[i].logo = (<img alt="logo" width={50} src={widgetsObject[i].logo} />);
          for (j in widgetsObject[i])
          //Checking for URL
            this.validURL(widgetsObject[i][j])
        }
        const widgetsList = Object.keys(widgetsObject).map(key=> ({
          ...widgetsObject[key],
          uid: key
        }));

        const filteredList = widgetsList.filter(row => row[Settings.defaultFilteredRows.USER.name] === Settings.defaultFilteredRows.USER.value)

        this.setState({
          widgets: filteredList,
          isLoading: true,
          index: filteredList
        });

        this.columns(filteredList);
      }
    });
     }

     validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    }

  columns(data) {
    let columns = Settings.schema;
    let defaultColumnWidths = [];
    columns.forEach(function(col) {
        defaultColumnWidths.push({ "columnName": col.name, "width": col.width });
    });
    let hiddenColumnNames = Settings.hidden;

    this.setState({
      columns,
      hiddenColumnNames,
      defaultColumnWidths,
      isLoading: false
    });
  }

  render() {
    return (
      <React.Fragment>
        <br />
        <br />
        <Grid container justify='center'>
          <Grid item xs={11} sm={10}>
          {!this.state.isLoading ? (
              <DxGrid
                classes={this.props.classes}
                columns={this.state.columns}
                rows={this.state.index}
                handleEditOpen={this.props.handleEditOpen}
                handleEditClose={this.props.handleEditClose}
                isEditOpen={this.props.isEditOpen}
                handleNewOpen={this.props.handleNewOpen}
                handleNewClose={this.props.handleNewClose}
                isNewOpen={this.props.isNewOpen}
                title={this.props.title}
                name={this.props.name}
                icon={this.props.icon}
                hiddenColumnNames={this.props.hiddenColumnNames}
                defaultColumnWidths={this.state.defaultColumnWidths}
              />
            ) : (
              <div>
              <CircularProgress className="spinner"/>
            </div>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const condition = authUser => authUser && !!authUser.roles[ROLES.USER];

export default compose(withAuthorization(condition)(withFirebase(WidgetGrid)));
