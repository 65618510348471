import { LinearProgress, withStyles } from '@material-ui/core';

export const RedLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#9c9c9c'
  },
  barColorPrimary: {
    backgroundColor: '#fa7d64'
  }
})(LinearProgress);

export const GreenLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#9c9c9c'
  },
  barColorPrimary: {
    backgroundColor: '#42F5B0'
  }
})(LinearProgress);

export const BlueLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#9c9c9c'
  },
  barColorPrimary: {
    backgroundColor: '#649bfa'
  }
})(LinearProgress);