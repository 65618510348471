import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import * as Actions from "../store/actions/sidebar"
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import PaletteIcon from '@material-ui/icons/Palette'

const mapStateToProps = (state) => ({
  isThemesOpen: state.sidebar.isThemesOpen
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch)
})

class ThemeSwitcher extends React.Component{
  render(){
    const { classes, actions } = this.props
    return (
      <>
        <ListItem button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            actions.sidebarThemesToggle(!this.props.isThemesOpen)
          }}>
          <ListItemIcon>
            <PaletteIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.listText}>Themes</Typography>
          </ListItemText>
          {this.props.isThemesOpen ? (
            <ExpandLess className={classes.listText} />
          ) : (
              <ExpandMore className={classes.listText} />
            )}
        </ListItem>
        <Collapse in={this.props.isThemesOpen} timeout='auto' unmountOnExit>
          <List component='div'>
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                actions.sidebarThemesSwitch('lightPieTheme')
              }}>
              <ListItemText>
                <Typography className={classes.listText}>Light</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                actions.sidebarThemesSwitch('darkPieTheme')
              }}>
              <ListItemText>
                <Typography className={classes.listText}>Dark</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitcher)
