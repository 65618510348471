import React, { useState } from 'react';
import {
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

const Filter = () => {
  const [cba, setCba] = useState(false);
  const [cbb, setCbb] = useState(false);
  const [cbc, setCbc] = useState(false);

  const handleFilterClick = name => event => {
    console.log(`Filter clicked... name: ${name}... event: ${event}`);
    switch (name) {
      case 'cba':
        setCba(!cba);
        break;
      case 'cbb':
        setCbb(!cbb);
        break;
      case 'cbc':
        setCbc(!cbc);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Trending</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox onClick={handleFilterClick('cba')} value='gilad' />
            }
            label='Nest'
            checked={cba}
          />
          <FormControlLabel
            control={
              <Checkbox onClick={handleFilterClick('cbb')} value='jason' />
            }
            label='Saber'
            checked={cbb}
          />
          <FormControlLabel
            control={
              <Checkbox onClick={handleFilterClick('cbc')} value='antoine' />
            }
            label='Fragment'
            checked={cbc}
          />
        </FormGroup>
      </FormControl>
      <br />
      <br />
      <br />
      <FormControl component='fieldset'>
        <FormLabel component='legend'>For You</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onClick={handleFilterClick} value='gilad' />}
            label='Silver'
          />
          <FormControlLabel
            control={<Checkbox onClick={handleFilterClick} value='jason' />}
            label='Flinch'
          />
          <FormControlLabel
            control={<Checkbox onClick={handleFilterClick} value='antoine' />}
            label='Awe'
          />
        </FormGroup>
      </FormControl>
      <br />
      <br />
      <br />
      <FormControl component='fieldset'>
        <FormLabel component='legend'>All Time</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onClick={handleFilterClick} value='gilad' />}
            label='Dexter'
          />
          <FormControlLabel
            control={<Checkbox onClick={handleFilterClick} value='jason' />}
            label='Sphinx'
          />
          <FormControlLabel
            control={<Checkbox onClick={handleFilterClick} value='antoine' />}
            label='Nova'
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default Filter;
