import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import * as Actions from "../store/actions/sidebar"
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import SettingsIcon from "@material-ui/icons/Settings"
import * as ROUTES from "../constants/routes"

const mapStateToProps = (state) => ({
  isSettingsOpen: state.sidebar.isSettingsOpen
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch)
})

class SideBarSettings extends React.Component {
  render() {
    const { actions, classes } = this.props
    return (
      <>
        <ListItem button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            actions.sidebarSettingsToggle(!this.props.isSettingsOpen)
          }}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.listText}>Settings</Typography>
          </ListItemText>
          {this.props.isSettingsOpen ? (
            <ExpandLess className={classes.listText} />
          ) : (
              <ExpandMore className={classes.listText} />
            )}
        </ListItem>
        <Collapse in={this.props.isSettingsOpen} timeout='auto' unmountOnExit>
          <List component='div'>
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                this.props.history.push(ROUTES.USER);
              }}>
              <ListItemText>
                <Typography className={classes.listText}>Users</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarSettings)