import React from "react";
import { Grid } from "@material-ui/core";
import DxGrid from "../DxGrid";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Settings from "../../constants/settings";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { compose } from "recompose";
import * as ROLES from "../../constants/roles";
import PieChart from "../../components/PieChart";
import { Typography } from "@material-ui/core";

const schema = [
  {
    key: 0,
    step: 0,
    name: "uid",
    title: "Uid",
    type: "hidden",
    roles: ["USER", "ADMIN"],
    width: 0
  },
  {
    name: "logo",
    title: "Logo",
    type: "image",
    roles: ["USER"],
    width: 100
  },
  {
    key: 1,
    step: 0,
    name: "name",
    title: "Name",
    type: "textbox",
    roles: ["USER"],
    width: 100
  },
  {
    key: 2,
    step: 0,
    name: "market_cap_formatted",
    title: "Market Cap",
    type: "textbox",
    roles: ["USER"],
    width: 100
  },
  {
    key: 2,
    step: 0,
    name: "price_formatted",
    title: "Price",
    type: "textbox",
    roles: ["USER"],
    width: 100
  },
  {
    key: 2,
    step: 0,
    name: "supply",
    title: "Supply",
    type: "textbox",
    roles: ["USER"],
    width: 100
  },
  {
    key: 2,
    step: 0,
    name: "volume_formatted",
    title: "Volume",
    type: "textbox",
    roles: ["USER"],
    width: 100
  },
  {
    key: 2,
    step: 0,
    name: "change_formatted",
    title: "Change",
    type: "textbox",
    roles: ["USER"],
    width: 100
  }
];

class WidgetGrid extends React.Component {
  state = {
    isLoading: true,
    data: [],
    error: null,
    columns: [],
    index: [],
    users: [],
    defaultColumnWidths: []
  };

  componentDidMount() {
    this.index();
  }

  index() {
    this.props.firebase.currencies().on("value", snapshot => {
      const widgetsObject = snapshot.val();
      if (widgetsObject) {
        let i = null;
        let j = null;
        for (i in widgetsObject) {
          widgetsObject[i].logo = (
            <img alt="logo" width={50} src={widgetsObject[i].logo} />
          );
          //Checking for URL
          // for (j in widgetsObject[i]) this.validURL(widgetsObject[i][j]);
        }
        const widgetsList = Object.keys(widgetsObject).map(key => ({
          ...widgetsObject[key],
          uid: key
        }));

        // const filteredList = widgetsList.filter(row => row[Settings.defaultFilteredRows.USER.name] === Settings.defaultFilteredRows.USER.value)

        this.setState({
          widgets: widgetsList,
          isLoading: true,
          index: widgetsList
        });

        this.columns(widgetsList);
      }
    });
  }

  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  columns(data) {
    let columns = schema;
    let defaultColumnWidths = [];
    columns.forEach(function(col) {
      defaultColumnWidths.push({ columnName: col.name, width: col.width });
    });
    let hiddenColumnNames = Settings.hidden;

    this.setState({
      columns,
      hiddenColumnNames,
      defaultColumnWidths,
      isLoading: false
    });
  }

  render() {

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    this.state.index.forEach(function(row) {
        if(typeof(row.market_cap) === "number") row.market_cap_formatted = formatter.format(row.market_cap);
        if(typeof(row.change) === "number")
        {
            row.change_color = "primary";
            if(row.change < 0) row.change_color = "error";
            row.change_formatted = formatter.format(row.change);
        }
        if(typeof(row.price) === "number") row.price_formatted = formatter.format(row.price);
        if(typeof(row.volume) === "number") row.volume_formatted = formatter.format(row.volume);
    });

    return (
      <React.Fragment>
        <br />
        <br />
        <Grid container justify="center">
          <Grid item xs={11} sm={10} >
            {!this.state.isLoading ? (
              <>
                <Grid container spacing="4">
                  <Grid item xs={12}>
                    <PieChart rows={this.state.index} 
                    selectedRow={this.props.selectedRow}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing="4">
                  <Grid item xs={12}>
                  <DxGrid
                  classes={this.props.classes}
                  columns={this.state.columns}
                  rows={this.state.index}
                  handleEditOpen={this.props.handleEditOpen}
                  handleEditClose={this.props.handleEditClose}
                  isEditOpen={this.props.isEditOpen}
                  handleNewOpen={this.props.handleNewOpen}
                  handleNewClose={this.props.handleNewClose}
                  isNewOpen={this.props.isNewOpen}
                  title={"Dashboard"}
                  name={"Dashboard"}
                  icon={this.props.icon}
                  hiddenColumnNames={this.props.hiddenColumnNames}
                  // defaultColumnWidths={this.state.defaultColumnWidths}
                />
                  </Grid>
                </Grid>


              </>
            ) : (
              <div>
                <CircularProgress className="spinner" />
              </div>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withFirebase(WidgetGrid);
