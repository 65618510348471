import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import SignUpForm from "../SignUp";
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { ThemeProvider } from '@material-ui/styles';
import WidgetManager from '../../containers/WidgetManager';
import UserManager from '../../containers/UserManager';
import SignUpManager from '../../containers/SignUpManager';
import { withFirebase } from '../Firebase';
import WebFont from 'webfontloader';
import Landing from '../Landing';
import Header from '../Header';
import Footer from '../Footer';
import MyEditor from '../Editor';
import Catalog from '../Catalog/index';
import ProjectCatalog from '../ProjectCatalog';
import UserProfile from '../UserProfile';
import ProjectProfile from '../ProjectProfile';
import PieCreate from '../PieCreate';
import PieWizard from '../PieWizard';
import * as Settings from '../../constants/settings';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';

import { withStyles } from '@material-ui/core/styles';
import { coreStyles } from './styles';
import { Container } from '@material-ui/core';
import * as Actions from '../../store/actions/authUser';
import { bindActionCreators } from 'redux';
import mockUsers from '../../mockData/users.json';
import Dashboard from '../../containers/Dashboard';
import Chart from '../../components/Chart';
import mockProjects from '../../mockData/projects.json';

const styles = theme => coreStyles(theme);

WebFont.load({
  google: {
    families: ['Montserrat:200,000,600']
  }
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
      selectedUserProfile: mockUsers['5oV3vu306Am4Cbx1GdN2OWWX5isn'],
      featuredUserProfile: mockUsers['5oV3vu306Am4Cbx1GdN2OWWX5isn'],
      userProfiles: [],
      projectProfiles: [],
      projectsToUsers: {},
      usersToProjects: {},
      selectedProjectProfile: mockProjects['CMuIZEkIGOomCx1Vl2D3JbHtXkvb'],
      featuredProjectProfile: mockProjects['CMuIZEkIGOomCx1Vl2D3JbHtXkvb']
    };
  }

  clearReduxAuth = () => {
    // this.props.actions.authUserSetRoles([]);
    // this.props.actions.authUserSetEmail(false);
    // this.props.actions.authUserSetDisplayName(false);
    // this.props.firebase.doSignOut();
    window.location.href = 'https://lpia.us/signout';
  };

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      this.props.firebase.user(authUser.uid).on('value', snapshot => {
        const usersObject = snapshot.val();
        this.props.actions.authUserSetRoles(usersObject.roles);
        this.props.actions.authUserSetEmail(usersObject.email);
        this.props.actions.authUserSetDisplayName(usersObject.username);
      });
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });

    this.props.firebase.users().on('value', snapshot => {
      let users = Object.values(snapshot.val()).filter(u =>
        u.hasOwnProperty('avatar')
      );
      this.setState({
        userProfiles: users,
        selectedUserProfile: snapshot.val()['0tbDRvKvEx8vwpF0yRJ0Srro1iEo'],
        featuredUserProfile: snapshot.val()['0tbDRvKvEx8vwpF0yRJ0Srro1iEo']
      });
    });

    this.props.firebase.widgets().on('value', snapshot => {
      let projects = Object.values(snapshot.val());
      this.setState({
        projectProfiles: projects,
        selectedProjectProfile: snapshot.val()['0FL4LmTgHCz8XN5TViEV3cm0cOiF'],
        featuredProjectProfile: snapshot.val()['0FL4LmTgHCz8XN5TViEV3cm0cOiF']
      });
    });

    this.props.firebase.currencies().on('value', snapshot => {
      let currencies = Object.values(snapshot.val());
      this.setState({ currencies });
    });

    this.props.firebase.projectsToUsers().on('value', snapshot => {
      this.setState({ projectsToUsers: snapshot.val() });
    });

    this.props.firebase.usersToProjects().on('value', snapshot => {
      this.setState({ usersToProjects: snapshot.val() });
    });
  }

  getRelatedProjects = (projectIds = []) => {
    return this.state.projectProfiles.filter(profile =>
      projectIds.some(id => id === profile.id)
    );
  };

  getRelatedUsers = (userIds = []) => {
    return this.state.userProfiles.filter(profile =>
      userIds.some(id => id === profile.id)
    );
  };

  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        <CssBaseline />
        <div className='App site'>
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/icon?family=Material+Icons'
          />
          <Router>
            {/* <Route exact path={ROUTES.LANDING} component={Landing} /> */}
            <Container>
              <div className='pageContainer siteContent'>
                <Header
                  history={this.props.history}
                  title={Settings.APPNAME}
                  icon={Settings.APPICON}
                  authUser={"john.doe@imperian.systems"}
                  themeIcon={this.props.themeIcon}
                  classes={this.props.classes}
                  displayName={"John Doe"}
                  clearReduxAuth={this.clearReduxAuth}
                />
                <Route
                  path={ROUTES.DASHBOARD}
                  render={props => <Dashboard {...props} />}
                />
                <Route
                  path={ROUTES.PROJECTCATALOG}
                  render={props => (
                    <ProjectCatalog
                      {...props}
                      featuredProjectProfile={this.state.featuredProjectProfile}
                      projectProfiles={this.state.projectProfiles}
                      selectProjectProfile={projectProfile => {
                        this.setState({
                          selectedProjectProfile: projectProfile
                        });
                      }}
                    />
                  )}
                />
                <Route exact path={ROUTES.ROOT} component={Dashboard} />
                {/* <Route path={ROUTES.SIGN_UP} component={SignUpForm} classes={this.props.classes} /> */}
                {/* <Route exact path={ROUTES.LANDING} component={Landing} /> */}
                <Route
                  path={ROUTES.SIGN_IN}
                  component={SignInPage}
                  classes={this.props.classes}
                />
                {/* <Route path={ROUTES.SIGN_UP} component={SignUpManager} classes={this.props.classes} /> */}
                <Route
                  path={ROUTES.SIGN_UP}
                  render={props => <SignUpManager {...props} />}
                />
                <Route
                  path={ROUTES.PASSWORD_FORGET}
                  component={PasswordForgetPage}
                />
                <Route path={ROUTES.HOME} component={Dashboard} />
                <Route
                  path={ROUTES.WIDGET}
                  render={props => <WidgetManager {...props} />}
                />
                <Route
                  path={ROUTES.USER}
                  render={props => <UserManager {...props} />}
                />
                <Route
                  path={ROUTES.EDITOR}
                  component={MyEditor}
                  classes={this.props.classes}
                />
                <Route
                  path={ROUTES.CATALOG}
                  render={props => (
                    <Catalog
                      {...props}
                      featuredUserProfile={this.state.featuredUserProfile}
                      userProfiles={this.state.userProfiles}
                      selectUserProfile={userProfile => {
                        this.setState({ selectedUserProfile: userProfile });
                        props.history.push(ROUTES.USERPROFILE);
                      }}
                    />
                  )}
                />
                <Route
                  path={ROUTES.USERPROFILE}
                  render={props => (
                    <UserProfile
                      {...props}
                      userProfile={this.state.selectedUserProfile}
                      selectProjectProfile={projectProfile => {
                        this.setState({
                          selectedProjectProfile: projectProfile
                        });
                        props.history.push(ROUTES.PROJECTPROFILE);
                      }}
                      relatedProjects={this.getRelatedProjects(
                        this.state.usersToProjects[
                          this.state.selectedUserProfile.id
                        ]
                      )}
                    />
                  )}
                />
                <Route
                  path={ROUTES.PROJECTPROFILE}
                  render={props => (
                    <ProjectProfile
                      {...props}
                      projectProfile={this.state.selectedProjectProfile}
                      selectUserProfile={userProfile => {
                        this.setState({ selectedUserProfile: userProfile });
                        props.history.push(ROUTES.USERPROFILE);
                      }}
                      relatedUsers={this.getRelatedUsers(
                        this.state.projectsToUsers[
                          this.state.selectedProjectProfile.id
                        ]
                      )}
                    />
                  )}
                />
                <Route
                  path='/chart'
                  render={props => (
                    <Chart
                      {...props}
                      projectProfile={this.state.selectedProjectProfile}
                      currencies={this.state.currencies}
                    />
                  )}
                />
                <Route
                  path={ROUTES.PIECREATE}
                  render={props => <PieCreate {...props} />}
                />
                <Route
                  path={ROUTES.PIEWIZARD}
                  render={props => <PieWizard {...props} />}
                />
                {this.props.email ? <Footer /> : <br />}
              </div>
            </Container>
          </Router>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.sidebar.theme,
  themeIcon: state.sidebar.themeIcon,
  roles: state.authUser.roles,
  email: state.authUser.email,
  displayName: state.authUser.displayName
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withFirebase(withAuthentication(App))));
