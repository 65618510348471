import React from "react";
import { Grid, Typography } from "@material-ui/core";

class Content extends React.Component {
 
  render() {
    const { classes, title, copy, image } = this.props;
    return (
      <React.Fragment>
        <div className={classes.contentBackground}>
          <div className={classes.contentLayout}>
            <Grid container justify="space-between">
              <Grid item xs={12} md={5}>
                <Typography
                  component='h1'
                  variant='h5'
                  color='inherit'
                  gutterBottom
                  className={classes.contentTitle}>
                  {title}
                </Typography>
                <Typography
                  color='inherit'
                  paragraph
                  className={classes.contentCopy}>
                  {copy}
                  <br />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <img src={image} alt='' className={classes.responsive} />
              </Grid>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Content;
