import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  AppBar,
  Button,
  Toolbar,
} from "@material-ui/core";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      loginOpen: false,
      contactOpen: false,
      isLoggedIn: false,
      userName: null
    };

    this.toggleDrawer = (side, loginOpen) => () => {
      this.setState({
        [side]: loginOpen
      });
    };

    this.handleContactOpen = () => {
      this.setState({ contactOpen: true });
    };

    this.handleContactClose = () => {
      this.setState({ contactOpen: false });
    };
  }

  render() {
    const { classes, history, homeRoute, loginRoute } = this.props;
    const Icon = this.props.icon;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static">
          <Toolbar className={classes.layout}>
            <Typography className={classes.logo}>
              <Button
                className={classes.logoButton}
                color="inherit"
                onClick={() => {
                  history.push(homeRoute);
                }}
              >
                <img src={Icon} alt="Imperian Logo" width="150" />
              </Button>
            </Typography>
            <Button className={classes.navButton} 
            variant="outlined"
            onClick={() => {
              history.push(loginRoute)
            }}
            >
              Login
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default Header;
