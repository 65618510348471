import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles, Grid, Typography } from "@material-ui/core";
import buildStyles from "./styles";
// import ImperianIcon from "../../UI/Icons/imperian-grey-transparentbg.png";

const styles = theme => buildStyles(theme);

class Footer extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
  render() {
    const { classes } = this.props;

    return (
        <div className={classes.layout}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.footerContent}>
                {/* <img
                  src={ImperianIcon}
                  alt='Imperian Logo'
                  width='400'
                  center='true'
                  className={classes.responsive}
                /> */}
                <Typography variant='caption' color='inherit' paragraph>
                  Copyright &copy; 2014 All Rights Reserved{" "}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
    );
  }
}

export default withStyles(styles)(withRouter(Footer));
