import React from "react";
import {
    Typography,
    Button,
    Grid,
    Hidden,
    AppBar,
    Toolbar
} from "@material-ui/core";

class Heading extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <AppBar style={{ background: 'transparent', boxShadow: 'none'}} position="static" color="inherit">
                    <Toolbar>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item sm={1} />
                            <Hidden xsDown>
                                <Grid item xs={11} sm={5} className={classes.titleGrid}>
                                    <Typography className='title'>
                                        {this.props.title}
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} sm={5} className={classes.newButtonGrid}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    className='newButton'
                                    onClick={this.props.handleNewOpen}
                                >
                                    New {this.props.name}
                                </Button>
                            </Grid>
                            <Grid item sm={1} />
                        </Grid>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        )
    }
}

export default Heading;