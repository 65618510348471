export const USER_MANAGER_NEW_TOGGLE = 'USER_MANAGER_NEW_TOGGLE'
export const USER_MANAGER_EDIT_TOGGLE = 'USER_MANAGER_EDIT_TOGGLE'
export const USER_MANAGER_OPTIONS = 'USER_MANAGER_OPTIONS'
export const USER_MANAGER_ROLES = 'USER_MANAGER_ROLES'
export const USER_MANAGER_SELECTED_ROW = 'USER_MANAGER_SELECTED_ROW'
export const USER_MANAGER_SELECTED_ROLES = 'USER_MANAGER_SELECTED_ROLES'
export const USER_MANAGER_HANDLE_CHANGE = 'USER_MANAGER_HANDLE_CHANGE'

export function userManagerNewToggle(bool) {
    return { type: USER_MANAGER_NEW_TOGGLE, bool }
}

export function userManagerEditToggle(bool) {
    return { type: USER_MANAGER_EDIT_TOGGLE, bool }
}

export function userManagerOptions(options) {
    return { type: USER_MANAGER_OPTIONS, options }
}

export function userManagerRoles(roles) {
    return { type: USER_MANAGER_ROLES, roles }
}

export function userManagerSelectedRow(row) {
    return { type: USER_MANAGER_SELECTED_ROW, row }
}

export function userManagerSelectedRoles(roles) {
    return { type: USER_MANAGER_SELECTED_ROLES, roles }
}

export function userManagerHandleChange(e) {
    return { type: USER_MANAGER_HANDLE_CHANGE, e }
}