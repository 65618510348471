import React from "react";
import { Modal, Card, CardContent } from "@material-ui/core";
// import { AuthUserContext } from "../Session";
// import Upload from "../FormFields/upload";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import { TextBox, Check, Picker } from "../FormFields";
import * as Settings from "../../constants/settings";

export const NewTextBox = props => {
  return props.schema.map(function(item, key) {
        if (item.step === props.activeStep) {
          if(item.roles.some((role) => {
            return props.roles[role]
          })){
            switch(item.type){
              case "textbox":
                return (
                  <TextBox
                    key={key}
                    fieldLabel={item.title}
                    fieldName={item.name}
                    handleChange={props.handleChange}
                  />
                );
              case "bool":
                return (
                  <Check
                    key={key}
                    label={item.title}
                    fieldName={item.name}
                    // handleClick={props.handleChange}
                  />
                );
                case "date":
                    return (
                      <Picker
                        key={key}
                        label={item.title}
                        fieldName={item.name}
                        // handleClick={props.handleChange}
                      />
                    );
              default:
                return true
            }
          } else {
            return true
          }
          // if (item.type === "textbox") {
          //   if (item.roles.some((role) => {
          //     return props.roles[role]
          //   })){
          //   return (
          //     <TextBox
          //       key={key}
          //       fieldLabel={item.title}
          //       fieldName={item.name}
          //       handleChange={props.handleChange}
          //     />
          //   );
          // } else {
          //   return true;
          // }
        // } else {
        //   return true;
        // }
      } else {
        return true;
      }
    });
  }

function NewWidget(props) {
  const { classes } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  return (
    <Modal open={props.open} onClose={props.close}>
      {/* <AuthUserContext.Consumer>
        {authUser => ( */}
      <div>
        <main className={classes.main}>
          <Card className={classes.paper}>
            <CardContent className={classes.form}>
              <form>
                <Stepper activeStep={activeStep} orientation="vertical">
             
                  {
                  Settings.steps.map((label, index) => {
                    if (label.roles.some((role) => {
                      return props.roles[role]
                    })){
                      return (
                        <Step key={index}>
                          <StepLabel>{label.title}</StepLabel>
                          <StepContent>
                            <NewTextBox
                              schema={props.schema}
                              handleCreate={props.handleCreate}
                              handleChange={props.handleChange}
                              activeStep={index}
                              roles={props.roles}
                            />
                            {/* File Upload Component */}
                            {/* <Grid container alignItems="center" justify="center">
                          <Grid item>
                            <Upload
                              uploadHandler={this.props.uploadHandler}
                              uploading={this.props.uploading}
                              uploadSuccess={this.props.uploadSuccess}
                              uploadStatus={this.props.uploadStatus}
                              uploadProgress={this.props.uploadProgress}
                              downloadURL={this.props.downloadURL}
                            />
                          </Grid>
                        </Grid> */}
                            <div className={classes.actionsContainer}>
                              <div>
                                <Button
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                  className={classes.button}
                                >
                                  Back
                                </Button>
                                {activeStep === Settings.steps.length - 1 ? (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={props.handleCreate}
                                  >
                                    Create {Settings.APPSHORTNAME}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.submit}
                                  >
                                    Next
                                  </Button>
                                )}
                              </div>
                            </div>
                          </StepContent>
                        </Step>
                      );
                    } else {
                      return true;
                    }
                    // label.roles.some((role) => {
                    //   if (props.roles[role]) {
                    //     return (
                    //       <Step key={index}>
                    //         <StepLabel>{label.title}</StepLabel>
                    //         <StepContent>
                    //           <NewTextBox
                    //             schema={props.schema}
                    //             handleCreate={props.handleCreate}
                    //             handleChange={props.handleChange}
                    //             activeStep={index}
                    //             roles={props.roles}
                    //           />
                    //           {/* File Upload Component */}
                    //           {/* <Grid container alignItems="center" justify="center">
                    //         <Grid item>
                    //           <Upload
                    //             uploadHandler={this.props.uploadHandler}
                    //             uploading={this.props.uploading}
                    //             uploadSuccess={this.props.uploadSuccess}
                    //             uploadStatus={this.props.uploadStatus}
                    //             uploadProgress={this.props.uploadProgress}
                    //             downloadURL={this.props.downloadURL}
                    //           />
                    //         </Grid>
                    //       </Grid> */}
                    //           <div className={classes.actionsContainer}>
                    //             <div>
                    //               <Button
                    //                 disabled={activeStep === 0}
                    //                 onClick={handleBack}
                    //                 className={classes.button}
                    //               >
                    //                 Back
                    //               </Button>
                    //               {activeStep === Settings.steps.length - 1 ? (
                    //                 <Button
                    //                   variant="outlined"
                    //                   color="primary"
                    //                   className={classes.submit}
                    //                   onClick={props.handleCreate}
                    //                 >
                    //                   Create Widget
                    //                 </Button>
                    //               ) : (
                    //                 <Button
                    //                   variant="outlined"
                    //                   color="primary"
                    //                   onClick={handleNext}
                    //                   className={classes.submit}
                    //                 >
                    //                   Next
                    //                 </Button>
                    //               )}
                    //             </div>
                    //           </div>
                    //         </StepContent>
                    //       </Step>
                    //     );
                    //   } else {
                    //     return true;
                    //   }
                    // })
                    })
                  }
                </Stepper>
              </form>
            </CardContent>
          </Card>
        </main>
      </div>
      {/* )}
      </AuthUserContext.Consumer> */}
    </Modal>
  );
}

export default NewWidget;
