import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import * as Actions from "../store/actions/sidebar"
import { Drawer, List } from '@material-ui/core'
import SidePanel from '../components/SidePanel'

class SideMenu extends React.Component{
  render() {
    const {actions} = this.props
    return (
      <div>
        <Drawer
          open={this.props.isOpen}
          onClose={() => {actions.sidebarToggle(false)}}>
          <div
            role='button'
            onClick={() => {actions.sidebarToggle(false)}}
          >
        <div>
        <List>
          <SidePanel
            history={this.props.history}
            handleLoginToggle={this.props.handleLoginToggle}
            title={this.props.title}
            themeIcon={this.props.themeIcon}
            authUser={this.props.authUser}
            classes={this.props.classes}
          />
        </List>
      </div>          
          </div>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.sidebar.isOpen,
  themeIcon: state.sidebar.themeIcon
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)
