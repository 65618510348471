import React from 'react';
import * as ROUTES from '../../constants/routes';
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Avatar,
  Typography
} from '@material-ui/core';
import {
  RedLinearProgress,
  GreenLinearProgress,
  BlueLinearProgress
} from './progressBars';

const ProfileCard = props => {
  const { history, projectProfile, selectProjectProfile } = props;

  const handleClick = e => {
    selectProjectProfile(projectProfile);
    history.push(ROUTES.PROJECTPROFILE);
  };
  return (
    <Card>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Grid container justify='center'>
            <Grid item>
              <div style={{ height: '40px' }}>
              <Avatar
                alt='Profile Picture'
                src={projectProfile.logo}
                style={{ height: '100%' }}
              /></div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography gutterBottom variant='h6' component='h6'>
                {`${projectProfile.name}`}
              </Typography>
            </Grid>
          </Grid>
          {projectProfile.rep && projectProfile.rep.current.map((tag, index) => (
            <>
              <Typography variant='body2' component='p'>
                {`#${tag.tag} ${tag.amount}`}
              </Typography>
              {index === 0 ? (
                <RedLinearProgress variant='determinate' value={tag.amount} />
              ) : index === 1 ? (
                <GreenLinearProgress variant='determinate' value={tag.amount} />
              ) : (
                <BlueLinearProgress variant='determinate' value={tag.amount} />
              )}
            </>
          ))}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProfileCard;
