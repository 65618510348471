import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import Firebase, { FirebaseContext } from './components/Firebase'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { lightPieTheme } from './components/App/styles'
import ImperianIcon from "./Icons/pie-transparent.png"
import reducers from './store/reducers/index'
import * as ROLES from "./constants/roles";
import { schema } from "./constants/settings"
import reduxThunk from 'redux-thunk'

const initialState = {
    sidebar: {
        theme: lightPieTheme,
        themeToggle: false,
        themeIcon: ImperianIcon,
        isOpen: false,
        isSettingsOpen: false,
        isThemesOpen: false,
    },
    userManager: {
        isNewOpen: false,
        isEditOpen: false,
        selectedRow: {},
        uid: false,
        username: false,
        email: false,
        password: false,
        password_confirmation: false,
        roles: {},
        selectedRoles: {},
        options: [
            { value: ROLES.ADMIN, label: ROLES.ADMIN },
            { value: ROLES.USER, label: ROLES.USER },
            { value: ROLES.SUSPENDED, label: ROLES.SUSPENDED }
        ],
    },
    widgetManager: {
        isNewOpen: false,
        isEditOpen: false,
        selectedRow: {},
        uid: false,
        schema: schema,
        values: [],
    },
    authUser: {
        displayName: false,
        email: false,
        roles: [],
    },
    catalog: {
        projects: [],
        currencies: [],
        selectedCurrencies: [],
    },
};
const store = createStore(reducers, initialState, applyMiddleware(createLogger(), reduxThunk));


ReactDOM.render(  
<FirebaseContext.Provider value={new Firebase()}>
<Provider store={store}>
    <App />
</Provider>
</FirebaseContext.Provider>
, 
document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
