import React from "react";
import { Grid, Typography } from "@material-ui/core";

class Banner extends React.Component {
 
  render() {
    const { classes, title, copy, image } = this.props;
    const bannerImage = {
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
    };

    return (
      <React.Fragment>
        <div className={classes.bannerImage} style={bannerImage}>
          <div className={classes.layout}>
            <Grid container>
              <Grid item md={10}>
                <div>
                  <Typography
                    component='h1'
                    variant='h4'
                    color='inherit'
                    gutterBottom
                    className={classes.bannerTitle}
                    >
                    {title}
                  </Typography>
                  <Typography variant='h6' color='inherit' paragraph className={classes.bannerContent}>
                  {copy}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          </div>
      </React.Fragment>
    );
  }
}

export default Banner;
