import color from "@material-ui/core/colors/indigo";

const buildStyles = theme => ({
  bannerImage: {
    backgroundSize: "cover"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  contentLayout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto"
    },
    padding: theme.spacing.unit * 5
  },
  bannerContent: {
    color: color[300],
    paddingLeft: `${theme.spacing.unit * 3}px`,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0
    },
    marginBottom: theme.spacing.unit * 2,
    fontWeight: "400"
  },
  bannerTitle: {
    color: color[300],
    paddingTop: `${theme.spacing.unit * 12}px`,
    paddingLeft: `${theme.spacing.unit * 3}px`,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0
    },
    marginTop: theme.spacing.unit * 20,
    fontWeight: "900"
  },
  contentBackground: {
  },
  contentTitle: {
    color: color[600]
  },
  contentCopy: {
    color: "primary"
  },
  responsive: {
    width: "100%",
    height: "auto"
  },
  featureContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0
    },
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    textAlign: "center"
  },
  title: {
    textAlign: "center",
    fontSize: "1.7rem"
  },
  copy: {
    textAlign: "center"
  },
  featureIcon: {
    fontSize: "6rem",
    color: color[600],
    marginBottom: theme.spacing.unit * 2
  },
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: "#ffffff"
  },
  menuButton: {
    marginLeft: -16,
    marginRight: 20
  },
  navButton: {
    "&:hover": {
      backgroundColor: color[600]
    },
    fontSize: "1rem"
  },
  logoButton: {
    "&:hover": {
    }
  },
  logo: {
    flexGrow: 1
  },
  toolbar: {},
  list: {
    width: 250
  },
  listText: {
    // color: "#cccccc",
    fontSize: "1rem",
    textTransform: "uppercase"
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

export default buildStyles;
