export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const SIDEBAR_SETTINGS_TOGGLE = 'SIDEBAR_SETTINGS_TOGGLE'
export const SIDEBAR_THEMES_TOGGLE = 'SIDEBAR_THEMES_TOGGLE'
export const SIDEBAR_THEMES_SWITCH = 'SIDEBAR_THEMES_SWITCH'

export function sidebarToggle(bool){
    return {type: SIDEBAR_TOGGLE, bool}
  }
  
  export function sidebarThemesToggle(bool){
    return {type: SIDEBAR_THEMES_TOGGLE, bool}
  }
  
  export function sidebarSettingsToggle(bool){
    return {type: SIDEBAR_SETTINGS_TOGGLE, bool}
  }

  export function sidebarThemesSwitch(theme){
    return {type: SIDEBAR_THEMES_SWITCH, theme}
  }