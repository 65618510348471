import {
  AUTH_USER_SET_ROLES,
  AUTH_USER_SET_DISPLAY_NAME,
  AUTH_USER_SET_EMAIL
} from "../actions/authUser";

const initState = {
  displayName: "John Doe",
  email: "imperian@imperian.systems",
  roles: {
    ADMIN: "ADMIN",
    USER: "USER"
  }
}

function authUser(state = initState, action) {
  switch (action.type) {
    case AUTH_USER_SET_ROLES:
      return {
        ...state,
        roles: action.roles
      };
    case AUTH_USER_SET_DISPLAY_NAME:
      return {
        ...state,
        displayName: action.value
      };
    case AUTH_USER_SET_EMAIL:
      return {
        ...state,
        email: action.value
      };
    default:
      return state;
  }
}

export default authUser;
