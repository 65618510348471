import React from 'react'

class PieWizard extends React.Component {
    render(){
        return(
            <>
Hello
            </>
        )
    }
}

export default PieWizard