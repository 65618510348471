import React from "react";
import { withRouter } from "react-router-dom";
import SideMenu from '../../containers/SideMenu'
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
} from "@material-ui/core";
import SideMenuButton from "../../containers/SideMenuButton"
import SimpleMenu from './userMenu'

class Header extends React.Component {
  render() {
    const { authUser, classes } = this.props;
    return (
      <div >
        {
          <>
            <AppBar className={classes.appBar} color='inherit' position='static'>
              <Toolbar>
                <SideMenuButton className='logoButton'/>
                <Typography className={classes.logoWrapper}>
                  <Button
                    className={classes.logoButton}
                    color='inherit'
                    onClick={() => {
                      this.props.history.push("/home");
                    }}>
                    <img src={this.props.themeIcon} alt='Logo' width='150' />
                  </Button>
                </Typography>
                <SimpleMenu 
                classes={classes}
                firebase={this.props.firebase}
                displayName={this.props.displayName}
                clearReduxAuth={this.props.clearReduxAuth}
                history={this.props.history}
                />
              </Toolbar>
            </AppBar>
            <SideMenu
              history={this.props.history}
              class={this.props.classes}
              handleLoginToggle={this.props.handleLoginToggle}
              title={this.props.title}
              icon={this.props.icon}
              authUser={this.props.authUser}
              classes={this.props.classes}
            />
          </>

        }
      </div>
    );
  }
}

export default (withRouter(Header));