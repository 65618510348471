import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles"
import buildStyles from '../components/WidgetManager/styles'
import EditWidget from "../components/UserManager/editWidget"
import NewWidget from "../components/UserManager/newWidget"
import WidgetGrid from "../components/UserManager/widgetGrid"
import * as ROLES from "../constants/roles";
import { withAuthorization } from '../components/Session'
import Heading from '../components/WidgetManager/heading'

import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import * as Actions from "../store/actions/userManager"

const mapStateToProps = (state) => ({
    isNewOpen: state.userManager.isNewOpen,
    isEditOpen: state.userManager.isEditOpen,
    selectedRow: state.userManager.selectedRow,
    uid: state.userManager.uid,
    username: state.userManager.username,
    email: state.userManager.email,
    password: state.userManager.password,
    password_confirmation: state.userManager.password_confirmation,
    roles: state.userManager.roles,
    selectedRoles: state.userManager.selectedRoles,
    options: state.userManager.options,
    error: state.userManager.error,
    title: "User Manager"
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
})

const styles = theme => buildStyles(theme);

class UserManager extends React.Component {
    render() {
        const { actions } = this.props
        return (
            <React.Fragment>
                <Heading
                    classes={this.props.classes}
                    handleNewOpen={() => {
                        actions.userManagerNewToggle(true)
                    }}
                    handleNewClose={() => {
                        actions.userManagerNewToggle(false)
                    }}
                    isNewOpen={this.props.isNewOpen}
                    title={this.props.title}
                    name="User"
                    icon="account_box"
                />
                <WidgetGrid
                    handleEditOpen={(row) => {
                        if (row.roles) {
                            const selectedRoles = Object.keys(row.roles).map(key => ({
                                value: key,
                                label: key
                            }));
                            actions.userManagerSelectedRoles(selectedRoles)
                        }
                        actions.userManagerSelectedRow(row)
                        actions.userManagerEditToggle(true)
                    }}
                    handleEditClose={() => {
                        actions.userManagerEditToggle(false)
                    }}
                    isEditOpen={this.props.isEditOpen}
                    handleNewOpen={() => {
                        actions.userManagerNewToggle(true)
                    }}
                    handleNewClose={() => {
                        actions.userManagerNewToggle(false)
                    }}
                    isNewOpen={this.props.isNewOpen}
                />
                <EditWidget
                    classes={this.props.classes}
                    open={this.props.isEditOpen}
                    close={() => {
                        actions.userManagerEditToggle(false)
                    }}
                    selectedRow={this.props.selectedRow}
                    selectedRoles={this.props.selectedRoles}
                    firebase={this.props.firebase}
                    // handleDelete={this.handleDelete}
                    handleSubmit={(e) => {
                        const { uid, roles } = this.props
                        this.props.firebase
                            .user(uid)
                            .update({
                                roles
                            })
                            .then(() => {
                                window.location.reload();
                            })
                            .catch(error => {
                                console.log(error)
                            });
                    }}
                    handleChange={(e) => {
                        actions.userManagerHandleChange(e)
                    }}
                    handleSelect={(e) => {
                        const selectedRoles = e;
                        const roles = {}
                        selectedRoles.map(function (data, key) {
                            return roles[data.value] = data.value
                        })
                        actions.userManagerRoles(roles)
                        actions.userManagerSelectedRoles(selectedRoles)
                    }}
                    handleCreate={(e) => {
                        const selectedRoles = [...this.props.selectedRoles, { value: e, label: e }]
                        const options = [...this.props.options, { value: e, label: e }];
                        const roles = this.props.roles;
                        roles[e] = e;
                        actions.userManagerRoles(roles)
                        actions.userManagerSelectedRoles(selectedRoles)
                        actions.userManagerOptions(options)
                    }}
                    options={this.props.options}
                />
                <NewWidget
                    classes={this.props.classes}
                    open={this.props.isNewOpen}
                    close={() => {
                        actions.userManagerNewToggle(false)
                    }}
                    firebase={this.props.firebase}
                    // handleDelete={this.handleDelete}
                    handleCreateUser={(e) => {
                        const { username, email, password, roles } = this.props
                        this.props.firebase
                            .doCreateUserWithEmailAndPassword(email, password)
                            .then(authUser => {
                                this.props.firebase.user(authUser.user.uid).set({
                                    username,
                                    email,
                                    roles
                                });
                            })
                            .then(() => {
                                window.location.reload();
                            })
                            .catch(error => {
                                console.log(error)
                            });
                    }}
                    handleChange={(e) => {
                        actions.userManagerHandleChange(e)
                    }}
                    handleSelect={(e) => {
                        const selectedRoles = e;
                        const roles = {}
                        selectedRoles.map(function (data, key) {
                            return roles[data.value] = data.value
                        })
                        actions.userManagerRoles(roles)
                        actions.userManagerSelectedRoles(selectedRoles)
                    }}
                    handleCreate={(e) => {
                        const selectedRoles = [...this.props.selectedRoles, { value: e, label: e }]
                        const options = [...this.props.options, { value: e, label: e }];
                        const roles = this.props.roles;
                        roles[e] = e;
                        actions.userManagerRoles(roles)
                        actions.userManagerSelectedRoles(selectedRoles)
                        actions.userManagerOptions(options)
                    }}
                    options={this.props.options}
                />
            </React.Fragment>
        );
    }
}


const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)((withRouter(withAuthorization(condition)((UserManager))))))
