import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Grid, Card, CardContent} from "@material-ui/core";

class MyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty()
    };
    console.log(this.props)
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  render() {
    const { editorState } = this.state
    const { classes } = this.props
    return (
      <>
        <br /><br />
        <Grid container>
          <Grid item xs={1}>

          </Grid>
          <Grid item xs={10}>
          <Card style={{ height: '100%' }}>
            <CardContent>
            <Editor
            classes={classes}
              editorState={editorState}
              toolbarClassName='editorToolbar'
              wrapperClassName='editorWrapper'
              editorClassName='editorClass'
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker',]
              }}
            />
            </CardContent>
          </Card>
          <Grid item xs={1}/>
          </Grid>
        </Grid>
        </>
    );
  }
}

export default MyEditor
