import React from "react";
import { Grid, Typography } from "@material-ui/core";
import WebOutlined from "@material-ui/icons/WebOutlined";
import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined";
import BuildOutlined from "@material-ui/icons/BuildOutlined";
import LayersOutlined from "@material-ui/icons/LayersOutlined";
import WorkOutline from "@material-ui/icons/WorkOutline";

class Features extends React.Component {
 
  render() {
    const { classes, featureArray } = this.props;

    function WebIcon(icon){
      switch (icon) {
        case 'WebOutlined': return <WebOutlined className={classes.featureIcon}/>
        case 'VerifiedUserOutlined': return <VerifiedUserOutlined className={classes.featureIcon}/>
        case 'BuildOutlined': return <BuildOutlined className={classes.featureIcon}/>
        case 'LayersOutlined': return <LayersOutlined className={classes.featureIcon}/>
        case 'WorkOutline': return <WorkOutline className={classes.featureIcon}/>
        default: return null
      }
    }

    return (
      <React.Fragment>
        <div className={classes.layout}>
          <Grid container>
            {featureArray.map(function(item, i) {
              return (
                <Grid key={i} item xs={12} md={Math.floor(12 / featureArray.length)}>
                  <div className={classes.featureContent}>
                    {WebIcon(item.icon)}
                    <Typography className={classes.title} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography className={classes.copy} paragraph>
                      {item.copy}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default Features;
