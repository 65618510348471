import { combineReducers } from 'redux'
import userManager from './userManager'
import widgetManager from './widgetManager'
import sidebar from './sidebar'
import authUser from './authUser'
import dashboard from './dashboard'
import catalog from './catalog'

export default combineReducers({
    sidebar: sidebar,
    userManager: userManager,
    widgetManager: widgetManager,
    authUser: authUser,
    dashboard: dashboard,
    catalog: catalog,
})