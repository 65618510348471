import React from "react";
import { Modal, Card, CardContent, Grid } from "@material-ui/core";
import { TextBox, SelectRole, Password, FormButton } from "../FormFields";

class NewWidget extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Modal open={this.props.open} onClose={this.props.close}>
        <div>
          <main className={classes.main}>
        <Card className={classes.paper}>
          <CardContent className={classes.form}>
            <TextBox
              fieldName='username'
              fieldLabel='User Name'
              handleChange={this.props.handleChange}
            />
            <TextBox
              fieldName='email'
              fieldLabel='Email'
              handleChange={this.props.handleChange}
            />
            <SelectRole
              handleChange={this.props.handleChange}
              handleSelect={this.props.handleSelect}
              handleCreate={this.props.handleCreate}
              options={this.props.options}
            />
            <Password handleChange={this.props.handleChange} />
            <Grid container alignItems='center' justify='center'>
              <Grid item>
                <FormButton
                  label='Create User'
                  handleSubmit={this.props.handleCreateUser}
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
        </main>
        </div>
      </Modal>
    );
  }
}

export default NewWidget
