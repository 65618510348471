import {
  CATALOG_CURRENCIES,
  CATALOG_PROJECTS,
  CATALOG_SELECTED_CURRENCIES
} from "../actions/catalog";

function dashboard(state = {}, action) {
  switch (action.type) {
    case CATALOG_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies
      };
    case CATALOG_PROJECTS:
      return {
        ...state,
        projects: action.projects
      };
    case CATALOG_SELECTED_CURRENCIES:
        // let currentCurrencies = state.selectedCurrencies;
        // if(currentCurrencies.includes(action.currencies))
        //   currentCurrencies.filter()
        // currentCurrencies.push(action.currencies)
      return {
        ...state,
        selectedCurrencies: action.currencies
      };
    default:
      return state;
  }
}

export default dashboard;
