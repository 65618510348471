import React from 'react';
import { Grid, Container, Hidden } from '@material-ui/core';
import FeaturedProfileCard from './FeaturedProfileCard';
import ProfileCard from './ProfileCard';
import Filter from './Filter';

const Catalog = props => {
  const { featuredUserProfile, userProfiles, selectUserProfile, history } = props;
  return (
    <>
      <br />
      <br />
      <br />
      <Container>
        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid item sm={2}>
              <Filter />
            </Grid>
          </Hidden>
          <Grid item sm={10} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FeaturedProfileCard
                  userProfile={featuredUserProfile}
                  selectUserProfile={selectUserProfile}
                  history={history}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {
                userProfiles.filter(u => u.id !== '0tbDRvKvEx8vwpF0yRJ0Srro1iEo').map(p => (
                <>
                  <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <ProfileCard
                      userProfile={p}
                      selectUserProfile={selectUserProfile}
                      history={history}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Catalog;
