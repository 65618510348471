import React from "react";
// import SignOutButton from "../SignOut";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
// import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode"
import AddShoppingCart from "@material-ui/icons/ShoppingCart"
import Face from "@material-ui/icons/Face"
import ThemeSwitcher from '../../containers/ThemeSwitcher'
import * as ROUTES from "../../constants/routes"
import AdminList from './adminList'

class LoggedInList extends React.Component {
  render() {

    this.handleCatalogClick = e => {
      this.props.history.push(ROUTES.CATALOG);
    };

    this.handleProjectCatalogClick = e => {
      this.props.history.push(ROUTES.PROJECTCATALOG);
    };

    this.handleEditorClick = e => {
      this.props.history.push(ROUTES.EDITOR);
    };

    this.handleProfileClick = e => {
      this.props.history.push(ROUTES.USERPROFILE);
    };

    const { classes } = this.props
    return (
      <div>
                <ListItem button onClick={this.handleCatalogClick}>
          <ListItemIcon className='listItemIcon'>
            <Face />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.listText}>User Catalog</Typography>
          </ListItemText>
        </ListItem>
        <ListItem button onClick={this.handleProjectCatalogClick}>
          <ListItemIcon className='listItemIcon'>
            <AddShoppingCart />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.listText}>Project Catalog</Typography>
          </ListItemText>
        </ListItem>
        {/* <ListItem button onClick={
          this.handleEditorClick
          }>
          <ListItemIcon className='listItemIcon'>
            <ChromeReaderMode />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.listText}>Editor</Typography>
          </ListItemText>
        </ListItem>
        <ListItem button onClick={
          this.handleProfileClick
        }>
          <ListItemIcon className='listItemIcon'>
            <Face />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.listText}>Profile</Typography>
          </ListItemText>
        </ListItem> */}
                <ThemeSwitcher history={this.props.history} classes={this.props.classes} />

        <AdminList
          history={this.props.history}
          classes={this.props.classes}
          onClick={this.onClick}
        />
        {/* <SignOutButton {...this.props} /> */}
      </div>
    );
  }
}
export default LoggedInList;