import React from "react";
import * as ROLES from "../../constants/roles";
import {
  withAuthorization
} from '../Session';
// import ThemeSwitcher from '../../containers/ThemeSwitcher'
import SidebarSettings from '../../containers/SidebarSettings'

class AdminList extends React.Component {
  render() {
    return (
      <>
        <SidebarSettings history={this.props.history} classes={this.props.classes} />
        {/* <ThemeSwitcher history={this.props.history} classes={this.props.classes} /> */}
      </>
    );
  }
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default withAuthorization(condition)(AdminList);