import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

import * as Settings from "../../constants/settings"

const config = {
  apiKey: Settings.apiKey,
  authDomain: Settings.authDomain,
  databaseURL: Settings.databaseURL,
  projectId: Settings.projectId,
  storageBucket: Settings.storageBucket,
  messagingSenderId: Settings.messagingSenderId,
  appId: Settings.appId
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
  }
  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    
    this.auth.signOut();
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Auth API ***
  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then(snapshot => {
            const dbUser = snapshot.val();
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            } else {
              if (dbUser.roles.SUSPENDED) {
                this.auth.signOut();
              }
            }
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser
            };
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref("users");

  // *** Widget API ***
  widget = uid => this.db.ref(`${Settings.databaseName}/${uid}`);
  widgets = () => this.db.ref(Settings.databaseName);

  // *** Linking Tables ***
  userToProjects = userId => this.db.ref(`users_to_projects/${userId}`);
  usersToProjects = () => this.db.ref('users_to_projects');
  projectToUsers = projectId => this.db.ref(`projects_to_users/${projectId}`);
  projectsToUsers = () => this.db.ref('projects_to_users');

  // *** Currencies API ***
  currencies = () => this.db.ref("currencies");

  // *** Storage ***
  storage = () => this.storage
}

export default Firebase;
