import React from "react";
import * as ROUTES from "../../constants/routes";
import * as Settings from "../../constants/settings"
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Box
} from "@material-ui/core";
import LoggedInList from "./loggedInList";
// import LoggedOutList from "./loggedOutList";

class SidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleAuth: null
    };
  }

  render() {
    const { authUser, classes } = this.props;
    this.handleClick = e => {
      this.props.history.push(ROUTES.WIDGET);
    };

    return (
      <>
        <Box
          // boxShadow={1}
          className={classes.sidebarLogoBox}
        >
          <Button
            className={classes.sidebarLogo}
            color='inherit'
            onClick={() => {
              this.props.history.push("/home");
            }}>
            <img className={classes.sidebarLogoImg} src={this.props.themeIcon} alt='Logo' />
          </Button>
        </Box>

        <br />

        {/* {authUser ?
          <>

            <ListItem style={{paddingRight:'4rem'}} button onClick={this.handleClick}>
              <ListItemIcon >
                <i className='material-icons '>{Settings.APPICON}</i>
              </ListItemIcon>
              <ListItemText>
                <Typography className={classes.listText}>
                  {Settings.APPNAME}
                </Typography>
              </ListItemText>
            </ListItem>
          </>
          :
          <></>
        } */}

        {authUser ?
          <>
            <LoggedInList
              history={this.props.history}
              classes={this.props.classes}
              onClick={this.props.handleLoginToggle}
            />
          </>
          :
          <></>
        }
        {/* {!authUser ?
          <>
            <LoggedOutList
              history={this.props.history}
              classes={this.props.classes}
              onClick={this.props.handleLoginToggle}
            />
          </>
          :
          <></>
        } */}
      </>
    );
  }
}

export default SidePanel;
