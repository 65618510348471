// Labels
export const APPNAME = "Project Manager";
export const APPSHORTNAME = "Project";
export const APPICON = "build";

// Firebase
export const apiKey = "AIzaSyCt6BxFQB3hjSKSG2g1OdMWXCd9kZC2MZY" ;
export const authDomain = "pie-project-c2674.firebaseapp.com";
export const databaseURL = "https://pie-project-c2674.firebaseio.com";
export const projectId = "pie-project-c2674";
export const storageBucket = "pie-project-c2674.appspot.com";
export const messagingSenderId = "1085830337170";
export const appId = "1:1085830337170:web:822ed08685f34f0e";
export const databaseName = "projects";

// DxGrid
export const schema = [
  {
    key: 0,
    step: 0,
    name: "uid",
    title: "Uid",
    type: "hidden",
    roles: ["USER","ADMIN"],
    width: 0
  },
  {
    name: "logo",
    title: "Logo",
    type: "image",
    roles: ["USER"],
    width: 100
  },
  {
    key: 1,
    step: 0,
    name: "name",
    title: "Name",
    type: "textbox",
    roles: ["USER"],
    width: 200
  },
  {
    key: 2,
    step: 0,
    name: "description",
    title: "Description",
    type: "textbox",
    roles: ["USER"],
    width: 400
  }
];

export const hidden = ["uid"];

export const steps = [
  { title: "Step 1", roles: ["USER"] },
  { title: "Step 2", roles: ["USER", "ADMIN"] }
];

export const defaultHiddenCols = {
  USER: ["uid", "widgetfield1", "widgetfield2", "widgetfield3"],
  ADMIN: ["uid", "widgetname", "widgetdata", "widgetfield2", "widgetfield3"]
};

export const defaultFilteredRows = {
  USER: {
//     name: "widgetfield1",
//     value: "filter"
  },
  ADMIN: {
//     name: "widgetfield2",
//     value: "filter"
  }
};
