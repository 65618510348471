import {
    USER_MANAGER_NEW_TOGGLE,
    USER_MANAGER_EDIT_TOGGLE,
    USER_MANAGER_SELECTED_ROW,
    USER_MANAGER_SELECTED_ROLES,
    USER_MANAGER_HANDLE_CHANGE,
    USER_MANAGER_ROLES,
    USER_MANAGER_OPTIONS
} from '../actions/userManager'


function userManager(state = {}, action) {
    switch (action.type) {
        case USER_MANAGER_NEW_TOGGLE:
            return {
                ...state,
                isNewOpen: action.bool
            }
        case USER_MANAGER_EDIT_TOGGLE:
            return {
                ...state,
                isEditOpen: action.bool
            }
        case USER_MANAGER_OPTIONS:
            return {
                ...state,
                roles: action.options,
            }
        case USER_MANAGER_ROLES:
            return {
                ...state,
                roles: action.roles,
            }
        case USER_MANAGER_SELECTED_ROW:
            return {
                ...state,
                selectedRow: action.row,
                uid: action.row.uid
            }
        case USER_MANAGER_SELECTED_ROLES:
            return {
                ...state,
                selectedRoles: action.roles
            }
        case USER_MANAGER_HANDLE_CHANGE:
            const { name, value } = action.e.target;
            return {
                ...state,
                [name]: value
            }
        default:
            return state;
    }
}

export default userManager