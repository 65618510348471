const buildStyles = theme => ({
  // layout: {
  //   width: "100%",
  //   [theme.breakpoints.up(900 + theme.spacing(6))]: {
  //     width: 900,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   },
  // },
  footerContent: {
    color: "#cccccc",
    // [theme.breakpoints.up("md")]: {
    //   paddingRight: 0
    // },
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  // responsive:{
  //   // width: "100%",
  //   height: "auto",
  //   minWidth: "200",
  //   maxWidth: "400",
  // }
});

export default buildStyles;
