import React from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { columns } from '../../constants/userColumns';

const getRows = relatedUsers => {
  return relatedUsers.map(u => ({
    ...u,
    avatar: <img alt='avatar' width={50} src={u.avatar} />,
    name: `${u.name.first} ${u.name.last}`
  }));
};

const UserGrid = props => {
  const { relatedUsers, selectUserProfile } = props;
  return (
    <Grid container justify='center'>
      <Grid item xs={12}>
        <Table style={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              {columns.map(
                col =>
                  col.type !== 'hidden' && (
                    <TableCell style={{ width: col.width }}>
                      {col.title}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getRows(relatedUsers).map(row => (
              <TableRow
                style={{ cursor: 'pointer' }}
                onClick={() => 
                  selectUserProfile(
                    relatedUsers.find(ru => ru.id === row.id)
                  )
                }
              >
                {columns.map(col =>
                    col.type !== 'hidden' && (
                      <TableCell style={{ width: col.width }}>
                        {row[col.name]}
                      </TableCell>
                    )
                  )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default UserGrid;
