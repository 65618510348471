import React from "react";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  SplineSeries,
  Legend
} from "@devexpress/dx-react-chart-material-ui";
import { withRouter } from "react-router-dom";

const ChartWidget = props => {
  const { projectProfile } = props;

  const nestData = projectProfile.rep
    ? projectProfile.rep.historical
        .filter(item => item.tag === "Nest")
        .map(e => ({ argumentNest: e.arg, valueNest: e.amount }))
    : [];
  const saberData = projectProfile.rep
    ? projectProfile.rep.historical
        .filter(item => item.tag === "Saber")
        .map(e => ({ argumentSaber: e.arg, valueSaber: e.amount }))
    : [];
  const fragmentData = projectProfile.rep
    ? projectProfile.rep.historical
        .filter(item => item.tag === "Fragment")
        .map(e => ({ argumentFragment: e.arg, valueFragment: e.amount }))
    : [];
  const chartData = nestData.concat(saberData).concat(fragmentData);

  const chartCurrencies = [
      {name: 'Nest', value: 'valueNest', argument: 'argumentNest'},
      {name: 'Saber', value: 'valueSaber', argument: 'argumentSaber'},
      {name: 'Fragment', value: 'valueFragment', argument: 'argumentFragment'},
  ]
    console.log(props.currencies)

  return (
    <>
      <Chart height="200" data={chartData}>
        <ArgumentAxis showGrid />
        <ValueAxis />
        {chartCurrencies.map(currency =>{
            return(
                <SplineSeries
                name={currency.name}
                valueField={currency.market_cap}
                argumentField={currency.id}
              />
            )
        })}
        <SplineSeries
          name="# Nest"
          valueField="valueNest"
          argumentField="argumentNest"
        />
        <SplineSeries
          name="# Saber"
          valueField="valueSaber"
          argumentField="argumentSaber"
        />
        <SplineSeries
          name="# Fragment"
          valueField="valueFragment"
          argumentField="argumentFragment"
        />
        <Legend />
      </Chart>
    </>
  );
};

export default withRouter(ChartWidget);
