export const ROOT = '/'
export const LANDING = '/landing'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const HOME = '/home'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const ADMIN = '/admin'
export const ADMIN_DETAILS = '/admin/:id'
export const USER = '/usermanager'
export const WIDGET = '/widgetmanager'
export const EDITOR = '/editor'
export const CATALOG = '/catalog'
export const USERPROFILE = '/userprofile'
export const PIECREATE = '/piecreate'
export const PIEWIZARD = '/piewizard'
export const DASHBOARD = '/dashboard'
export const PROJECTCATALOG = '/projectcatalog'
export const PROJECTPROFILE = '/projectprofile'
