import "date-fns";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Switch,
  FormControlLabel,
  Checkbox,
  Tooltip
} from "@material-ui/core";
import React from "react";
import MySelect from "../Select";
import AlertDialog from "../Dialog";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

// export class Name extends React.Component {
//   render() {
//     return (
//       <FormControl margin='normal' fullWidth>
//         <InputLabel htmlFor='username'>Name</InputLabel>
//         <Input
//           id='username'
//           name='username'
//           defaultValue={this.props.defaultValue}
//           autoFocus
//           required
//           onChange={this.props.handleChange}
//         />
//       </FormControl>
//     );
//   }
// }

// export class WidgetName extends React.Component {
//   render() {
//     return (
//       <FormControl margin='normal' fullWidth>
//         <InputLabel htmlFor='widgetname'>Name</InputLabel>
//         <Input
//           id='widgetname'
//           name='widgetname'
//           defaultValue={this.props.defaultValue}
//           autoFocus
//           onChange={this.props.handleChange}
//         />
//       </FormControl>
//     );
//   }
// }

export const TextBox = props => {
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel htmlFor={props.fieldName}>{props.fieldLabel}</InputLabel>
      <Input
        id={props.fieldName}
        name={props.fieldName}
        defaultValue={props.defaultValue}
        // autoFocus
        onChange={props.handleChange}
        // key={props.itemkey}
      />
    </FormControl>
  );
};

// export class WidgetData extends React.Component {
//   render() {
//     return (
//       <FormControl margin='normal' fullWidth>
//         <InputLabel htmlFor='widgetdata'>Data</InputLabel>
//         <Input
//           id='widgetdata'
//           name='widgetdata'
//           defaultValue={this.props.defaultValue}
//           autoFocus
//           onChange={this.props.handleChange}
//         />
//       </FormControl>
//     );
//   }
// }

// export class Email extends React.Component {
//   render() {
//     return (
//       <FormControl margin='normal' fullWidth>
//         <InputLabel htmlFor='email'>Email Address</InputLabel>
//         <Input
//           id='email'
//           name='email'
//           defaultValue={this.props.defaultValue}
//           type='email'
//           onChange={this.props.handleChange}
//         />
//       </FormControl>
//     );
//   }
// }

export class SelectOrganization extends React.Component {
  render() {
    return (
      <FormControl margin="normal" fullWidth>
        <MySelect
          options={this.props.options}
          isMulti={false}
          name={"selectOrg"}
          placeholder={"Select Organization"}
          handleSelect={this.props.handleOrgSelect}
          selected={this.props.selectedOrg}
          handleCreate={this.props.handleOrgCreate}
        />
      </FormControl>
    );
  }
}

export class SelectRole extends React.Component {
  render() {
    return (
      <FormControl margin="normal" fullWidth>
        <MySelect
          options={this.props.options}
          isMulti={true}
          name={"selectRole"}
          placeholder={"Select Role"}
          handleSelect={this.props.handleSelect}
          selectedRoles={this.props.selectedRoles}
          handleCreate={this.props.handleCreate}
          handleChange={this.props.handleChange}
        />
      </FormControl>
    );
  }
}

export class Password extends React.Component {
  render() {
    return (
      <React.Fragment>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            name="password"
            defaultValue=""
            onChange={this.props.handleChange}
            type="password"
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="password_confirmation">Confirm</InputLabel>
          <Input
            id="password_confirmation"
            name="password_confirmation"
            defaultValue=""
            onChange={this.props.handleChange}
            type="password"
          />
        </FormControl>
      </React.Fragment>
    );
  }
}

export class FormButton extends React.Component {
  render() {
    return (
      <FormControl margin="normal">
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className="classes.submit"
          onClick={this.props.handleSubmit}
        >
          {this.props.label}
        </Button>
      </FormControl>
    );
  }
}

export const DeleteButton = props => {
  return (
    <FormControl margin="normal">
      <AlertDialog
        buttonLabel={props.buttonLabel}
        dialogContentText={props.dialogContentText}
        dialogTitle={props.dialogTitle}
        handleDelete={props.handleDelete}
      />
    </FormControl>
  );
};

export const ThemeSwitch = props => {
  return (
    <FormControl margin="normal">
      <Switch checked={props.isChecked} onChange={props.handleChange} />
    </FormControl>
  );
};

export const Check = props => {
  if (props.tooltip)
    return (
      <Tooltip title={props.tooltip} placement="right-start">
        <FormControlLabel
          control={<Checkbox onClick={e => props.handleClick(e)} value={props.value} />}
          label={props.label}
          checked={props.checked}
        />
      </Tooltip>
    );
  else
    return (
      <FormControlLabel
        control={<Checkbox onClick={props.handleClick} value={props.value} />}
        label={props.label}
        checked={props.checked}
      />
    );
};

export const Picker = props => {
  return (
    <FormControl margin="normal" fullWidth>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          autoOk="true"
          id={props.fieldName}
          label={props.fieldLabel}
          value={props.defaultValue}
          onChange={() => {}}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};
