export const columns = [
  {
    key: 0,
    step: 0,
    name: "uid",
    title: "Uid",
    type: "hidden",
    roles: ["USER","ADMIN"],
    width: 0
  },
  {
    name: "avatar",
    title: "Avatar",
    type: "image",
    roles: ["USER"],
    width: 100
  },
  {
    key: 1,
    step: 0,
    name: "name",
    title: "Name",
    type: "textbox",
    roles: ["USER"],
    width: 200
  },
  {
    key: 2,
    step: 0,
    name: "company",
    title: "Company",
    type: "textbox",
    roles: ["USER"],
    width: 400
  },
  {
    key: 3,
    step: 0,
    name: "note",
    title: "Note",
    type: "textbox",
    roles: ["USER"],
    width: 400
  }
];