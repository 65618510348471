import React from "react";
import { Modal } from "@material-ui/core";
import { Card, CardContent, Grid } from "@material-ui/core";
import { TextBox, SelectRole, FormButton } from "../FormFields";


class EditWidget extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Modal open={this.props.open} onClose={this.props.close}>
                <div>
          <main className={classes.main}>
        <Card className={classes.paper}>
          <CardContent className={classes.form}>
            <TextBox
              fieldName='username'
              fieldLabel='User Name'
              handleChange={this.props.handleChange}
              defaultValue={this.props.selectedRow.username}
            />
            <SelectRole
              selectedRoles={this.props.selectedRoles}
              handleSelect={this.props.handleSelect}
              handleCreate={this.props.handleCreate}
              handleChange={this.props.handleChange}
              options={this.props.options}
            /><br /><br />
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <FormButton
                  label='Update'
                  handleSubmit={this.props.handleSubmit}
                />
              </Grid>
              {/* <Grid item xs={4} /> */}
              {/* <Grid item xs={4}>
                <DeleteButton
                  buttonLabel="Suspend"
                  dialogContentText="You are about to suspend this user. Are you sure?"
                  dialogTitle="Suspend Confirmation"
                  handleDelete={this.props.handleDelete}
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
        </main>
        </div>
      </Modal>
    );
  }
}

export default EditWidget