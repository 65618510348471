import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import {
  Chart,
  PieSeries,
  Legend,
  chartData,
  ArgumentAxis,
  ValueAxis,
  SplineSeries
} from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
export default class Demo extends React.PureComponent {
  render() {
    return (
      <>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <Paper>
              <Chart
                style={{ maxHeight: "20rem" }}
                data={this.props.rows.splice(0, 5)}
              >
                <ArgumentAxis showGrid />
                <ValueAxis />
                {/* <SplineSeries
          name='#Nest'
          valueField='market_cap'
          argumentField='name'
        />
        <SplineSeries
          name='#Saber'
          valueField='market_cap'
          argumentField='name'
        /> */}
                <SplineSeries
                  name="#Fragment"
                  valueField="market_cap"
                  argumentField="name"
                />
              </Chart>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Chart data={this.props.rows.splice(0, 5)}>
                <PieSeries valueField="market_cap" argumentField="name" />
                <Animation />
                <Legend />
              </Chart>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
