import React, {useState} from "react";
import {
  Container,
  Grid,
  Typography,
  Avatar,
  Paper,
  LinearProgress,
  withStyles,
  Button
} from "@material-ui/core";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  SplineSeries
} from "@devexpress/dx-react-chart-material-ui";
import { withRouter } from "react-router-dom";
import ProjectCard from "./ProjectCard";
import ProjectGrid from "./ProjectGrid";

const RedLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#9c9c9c"
  },
  barColorPrimary: {
    backgroundColor: "#fa7d64"
  }
})(LinearProgress);

const GreenLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#9c9c9c"
  },
  barColorPrimary: {
    backgroundColor: "#42F5B0"
  }
})(LinearProgress);

const BlueLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#9c9c9c"
  },
  barColorPrimary: {
    backgroundColor: "#649bfa"
  }
})(LinearProgress);

const Profile = props => {
  const { userProfile, relatedProjects, selectProjectProfile } = props;
  const [projectMoreClicked, setProjectMoreClicked] = useState(false);

  const nestData = userProfile.rep ? userProfile.rep.historical.filter(item => item.tag === 'Nest').map(e => (
    { argumentNest: e.arg, valueNest: e.amount }
  )) : [];
  const saberData = userProfile.rep ? userProfile.rep.historical.filter(item => item.tag === 'Saber').map(e => (
    { argumentSaber: e.arg, valueSaber: e.amount }
  )): [];
  const fragmentData = userProfile.rep ? userProfile.rep.historical.filter(item => item.tag === 'Fragment').map(e => (
    { argumentFragment: e.arg, valueFragment: e.amount }
  )) : [];
  const chartData = nestData.concat(saberData).concat(fragmentData);

  return (
    <>
      <Container>
        <Grid container spacing={4} style={{ padding: "2rem" }}>
          <Grid item xs={12} sm={3}>
            <Avatar
              style={{
                minWidth: "10rem",
                minHeight: "10rem",
                marginLeft: "auto"
              }}
              alt="Remy Sharp"
              src={userProfile.avatar}
            />
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={7}>
            <Typography variant="h3" gutterBottom>
              {`${userProfile.name.first} ${userProfile.name.last}`}
            </Typography>
            <br />
            <br />
            <Typography>
              {userProfile.note}
            </Typography>
            <br />
            <br />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper>
              <Container>
                <br />
                <Typography variant="h6">Reputation Currency</Typography>
                <br />
                <Typography gutterBottom variant="body2" component="p">
                  <Grid container justify="space-between">
                    <Grid item># Nest</Grid>
                    <Grid item>{userProfile.rep.current[0].amount}</Grid>
                  </Grid>
                </Typography>
                <RedLinearProgress variant="determinate" value={userProfile.rep.current[0].amount} />

                <Typography
                  style={{ paddingTop: "1rem" }}
                  gutterBottom
                  variant="body2"
                  component="p"
                >
                  <Grid container justify="space-between">
                    <Grid item># Saber</Grid>
                    <Grid item>{userProfile.rep.current[1].amount}</Grid>
                  </Grid>
                </Typography>
                <GreenLinearProgress variant="determinate" value={userProfile.rep.current[1].amount} />

                <Typography
                  style={{ paddingTop: "1rem" }}
                  gutterBottom
                  variant="body2"
                  component="p"
                >
                  <Grid container justify="space-between">
                    <Grid item># Fragment</Grid>
                    <Grid item>{userProfile.rep.current[2].amount}</Grid>
                  </Grid>{" "}
                </Typography>
                <BlueLinearProgress variant="determinate" value={userProfile.rep.current[2].amount} />
                <br />
                <Button variant="outlined">more</Button>
                <br />
                <br />
              </Container>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              style={{
                marginLeft: ".5rem",
                marginRight: ".5rem",
                padding: ".5rem"
              }}
            >
              <Typography variant="h6">Currency Trends</Typography>
              <br />
              <Container>
                <Grid item xs={12}>
                  <Chart
                    height="200"
                    data={chartData}
                  >
                    <ArgumentAxis showGrid />
                    <ValueAxis />
                    <SplineSeries
                      name="# Nest"
                      valueField="valueNest"
                      argumentField="argumentNest"
                    />
                    <SplineSeries
                      name="# Saber"
                      valueField="valueSaber"
                      argumentField="argumentSaber"
                    />
                    <SplineSeries
                      name="# Fragment"
                      valueField="valueFragment"
                      argumentField="argumentFragment"
                    />
                  </Chart>
                </Grid>
              </Container>
            </Paper>
          </Grid>
        </Grid>
        <br />
        <br />
        <Paper style={{ padding: "2rem" }}>
          <Typography variant="h4">Projects</Typography>
          <br />
          <ProjectGrid
            relatedProjects={relatedProjects || []}
            selectProjectProfile={selectProjectProfile}
          />
          {/* <Grid container spacing={4} justify="flex-start" >
            {trimmedProjects.map((project, index) => (
              <ProjectCard 
                key={`${project.name}-${index}`}
                project={project}
                selectProjectProfile={() => selectProjectProfile(project)}
              />
            ))}
          </Grid>
          <br />
          <Button variant="outlined" onClick={() => setProjectMoreClicked(!projectMoreClicked)}>more</Button>
          <br /> */}
        </Paper>
      </Container>
    </>
  );
};

export default withRouter(Profile);
