import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import buildStyles from "../components/WidgetManager/styles";
import { withFirebase } from "../components/Firebase";
import * as ROUTES from "../constants/routes";
import SignUp from "../components/SignUp";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../store/actions/userManager";

const mapStateToProps = state => ({
  isNewOpen: state.userManager.isNewOpen,
  isEditOpen: state.userManager.isEditOpen,
  selectedRow: state.userManager.selectedRow,
  uid: state.userManager.uid,
  username: state.userManager.username,
  email: state.userManager.email,
  password: state.userManager.password,
  password_confirmation: state.userManager.password_confirmation,
  roles: state.userManager.roles,
  selectedRoles: state.userManager.selectedRoles,
  options: state.userManager.options,
  error: state.userManager.error,
  title: "User Manager"
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const styles = theme => buildStyles(theme);

class SignUpManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }
  render() {
    // const onSubmit = event => {
    //   const { username, email, password } = this.state;
    //   this.props.firebase
    //     .doCreateUserWithEmailAndPassword(email, password)
    //     .then(authUser => {
    //       // Create a user in your Firebase realtime database
    //       return this.props.firebase.user(authUser.user.uid).set({
    //         username,
    //         email
    //       });
    //     })
    //     .then(() => {
    //       this.props.history.push(ROUTES.HOME);
    //     })
    //     .catch(error => {
    //       this.setState({ error });
    //     });

    //   event.preventDefault();
    // };
    const { actions } = this.props;

    // this.handleChange = e => {
    //   const { name, value } = e.target;
    //   this.setState({ [name]: value });
    // };

    return (
      <React.Fragment>
        <SignUp
          classes={this.props.classes}
          error={this.state.error}
          handleChange={e => {
            actions.userManagerHandleChange(e);
          }}
          handleCreate={e => {
            const { username, email, password } = this.props;
            let roles = { USER: "USER" };
            this.props.firebase
              .doCreateUserWithEmailAndPassword(email, password)
              .then(authUser => {
                this.props.firebase.user(authUser.user.uid).set({
                  username,
                  email,
                  roles
                });
              })
              .then(() => {
                this.props.history.push(ROUTES.HOME);
              })
              .catch(error => {
                this.setState({ error });
              });
            e.preventDefault();
          }}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withFirebase(withRouter(SignUpManager))));
