import React from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import * as Settings from '../../constants/settings';


const getRows = relatedProjects => {
  return relatedProjects.map(p => ({
    ...p,
    logo: <img alt='logo' width={50} src={p.logo} />
  }));
};

const ProjectGrid = props => {
  const { relatedProjects, selectProjectProfile } = props;
  console.log('related projects: ', relatedProjects);
  console.log('rows: ', getRows(relatedProjects));
  return (
    <Grid container justify='center'>
      <Grid item xs={12}>
        <Table style={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              {Settings.schema.map(
                col =>
                  col.type !== 'hidden' && (
                    <TableCell style={{ width: col.width }}>
                      {col.title}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getRows(relatedProjects).map(row => (
              <TableRow
                style={{cursor: 'pointer'}}
                onClick={() =>
                  selectProjectProfile(
                    relatedProjects.find(rp => rp.id === row.id)
                  )
                }>
                {Settings.schema.map(
                  col =>
                    col.type !== 'hidden' && (
                      <TableCell style={{ width: col.width }}>
                        {row[col.name]}
                      </TableCell>
                    )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default ProjectGrid;
