import React from 'react';
import * as ROUTES from '../../constants/routes';
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Avatar,
  Typography
} from '@material-ui/core';
import {
  RedLinearProgress,
  GreenLinearProgress,
  BlueLinearProgress
} from './progressBars';

const ProfileCard = props => {
  const { history, userProfile, selectUserProfile } = props;

  const handleClick = e => {
    selectUserProfile(userProfile);
  };
  return (
    <Card>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Grid container justify='center'>
            <Grid item>
              <Avatar
                alt='Profile Picture'
                src={userProfile.avatar}
                style={{ height: '100%' }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography gutterBottom variant='h6' component='h6'>
                {`${userProfile.name && userProfile.name.first} ${userProfile.name && userProfile.name.last}`}
              </Typography>
            </Grid>
          </Grid>
          {userProfile.rep && userProfile.rep.current.map((tag, index) => (
            <>
              <Typography variant='body2' component='p'>
                {`#${tag.tag} ${tag.amount}`}
              </Typography>
              {index === 0 ? (
                <RedLinearProgress variant='determinate' value={tag.amount} />
              ) : index === 1 ? (
                <GreenLinearProgress variant='determinate' value={tag.amount} />
              ) : (
                <BlueLinearProgress variant='determinate' value={tag.amount} />
              )}
            </>
          ))}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProfileCard;
