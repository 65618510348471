import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import buildStyles from "../components/WidgetManager/styles";
import WidgetGrid from "../components/Dashboard/widgetGrid";
import * as ROLES from "../constants/roles";
import { withAuthorization } from "../components/Session";
import { withFirebase } from "../components/Firebase";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../store/actions/dashboard";
import * as Settings from "../constants/settings";

const mapStateToProps = state => ({
  isNewOpen: state.widgetManager.isNewOpen,
  isEditOpen: state.widgetManager.isEditOpen,
  selectedRow: state.dashboard.selectedRow,
  error: state.widgetManager.error,
  title: Settings.APPNAME,
  schema: state.widgetManager.schema,
  values: state.widgetManager.values,
  shortName: Settings.APPSHORTNAME,
  appIcon: Settings.APPICON,
  authUserRoles: state.authUser.roles
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const styles = theme => buildStyles(theme);

class Dashboard extends React.Component {
  render() {
    const { actions } = this.props;

    return (
      <React.Fragment>
        {/* <Heading
          classes={this.props.classes}
          handleNewOpen={() => {
            actions.widgetManagerNewToggle(true);
          }}
          handleNewClose={() => {
            actions.widgetManagerNewToggle(false);
          }}
          isNewOpen={this.props.isNewOpen}
          title={this.props.title}
          name={this.props.shortName}
          icon={this.props.appIcon}
        /> */}
        <WidgetGrid
          handleEditOpen={row => {
            actions.dashboardSelectedRow(row);
            // actions.widgetManagerEditToggle(true);
          }}
          handleEditClose={() => {
            actions.widgetManagerEditToggle(false);
          }}
          isEditOpen={this.props.isEditOpen}
          handleNewOpen={() => {
            actions.widgetManagerNewToggle(true);
          }}
          handleNewClose={() => {
            actions.widgetManagerNewToggle(false);
          }}
          isNewOpen={this.props.isNewOpen}
          hiddenColumnNames={Settings.defaultHiddenCols.ADMIN}
        />
      
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    withFirebase(withRouter(Dashboard))
  )
);
