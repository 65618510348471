import {
  DASHBOARD_SELECTED_ROW,
} from "../actions/dashboard";
// import { create } from '../../components/WidgetManager/createWidget'

function dashboard(state = {}, action) {
  switch (action.type) {
    case DASHBOARD_SELECTED_ROW:
      return {
        ...state,
        selectedRow: action.row,
      };
    default:
      return state;
  }
}

export default dashboard;
