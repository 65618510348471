import React from 'react';
import { Grid, Container, Hidden } from '@material-ui/core';
import FeaturedProfileCard from './FeaturedProfileCard';
import ProfileCard from './ProfileCard';
import Filter from './Filter';

const ProjectCatalog = props => {
  const { featuredProjectProfile, projectProfiles, selectProjectProfile, history } = props;
  return (
    <>
      <br />
      <br />
      <br />
      <Container>
        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid item sm={2}>
              <Filter />
            </Grid>
          </Hidden>
          <Grid item sm={10} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FeaturedProfileCard
                  projectProfile={featuredProjectProfile}
                  selectProjectProfile={selectProjectProfile}
                  history={history}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {
                projectProfiles.filter(p => p.id !== '0FL4LmTgHCz8XN5TViEV3cm0cOiF').map(p => (
                <>
                  <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <ProfileCard
                      projectProfile={p}
                      selectProjectProfile={selectProjectProfile}
                      history={history}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ProjectCatalog;
