import React from 'react'
import {
    Grid,
    Container,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Typography,
} from '@material-ui/core'

const copyList = [
    {
        title: 'Accelerator',
        copy: `Startup accelerators, also known as seed accelerators,
        are fixed-term, cohort-based programs that include seed
        investment, connections, mentorship, educational components,
        and culminate in a public pitch event or demo day to
        accelerate growth.`,
        icon: 'whatshot'
    },
    {
        title: 'Incubator',
        copy: `A business incubator is a company that helps new and
        startup companies to develop by providing services
        such as management training or office space. The
        National Business Incubation Association defines incubators
        as a catalyst for either regional or national economic
        development.`,
        icon: 'child_friendly'
    },
    {
        title: 'Laboratory',
        copy: `A laboratory (colloquially lab) is a facility that
        provides controlled conditions in which scientific or
        technological research, experiments, and measurement may
        be performed.`,
        icon: 'developer_board'
    },
    {
        title: 'Project',
        copy: `Contemporary business and science treat as a project any
        undertaking, carried out individually or collaboratively and
        possibly involving research or design that is carefully planned
        to achieve a particular aim.`,
        icon: 'poll'
    },
    {
        title: 'Team',
        copy: `A team is a group of individuals (human or non-human) working
        together to achieve their goal. As defined by Professor Leigh
        Thompson of the Kellogg School of Management, "[a] team is a group
        of people who are interdependent with respect to information,
        resources, and skills and who seek to combine their efforts to
        achieve a common goal."`,
        icon: 'group'
    },
    {
        title: 'User',
        copy: `A user is a person who utilizes a computer or network service.`,
        icon: 'person_outlined'
    },
]

const copyCard = (title, copy, icon, key) => {
    return (
        <Grid item xs={12} sm={6} lg={4} key={key} >
            <Card >
                <CardActionArea
                    onClick={handleClick}
                    style={{
                        height: 'auto',
                        width: 'auto',
                    }}
                >
                    <CardHeader
                        title={title}
                    >
                    </CardHeader>
                    <CardContent>
                        <Typography>
                            {copy}
                        </Typography>
                        <Grid container justify='flex-end' alignItems='flex-end'>
                            <Grid item xs={12}>
                            <Typography >
                            <i className='material-icons ' >{icon}</i>
                        </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

const CopyCards = (props) => {
    let cards = []
    for (var i = 0; i < props.copyList.length; i++)
        cards.push(
            copyCard(props.copyList[i].title, props.copyList[i].copy, props.copyList[i].icon, props.copyList[i].key)
        )
    return cards
}

const handleClick = (e) => {
    console.log(e)
}

class PieCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: true,
        };
    }
    render() {

        return (
            <>
                <Container>
                    <Grid container spacing={4}>
                        <CopyCards
                            copyList={copyList}
                        />
                    </Grid>
                </Container>
            </>
        )
    }
}

export default PieCreate