import React from 'react';
import * as ROUTES from '../../constants/routes';
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Container,
  Avatar,
  Typography,
  Paper
} from '@material-ui/core';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  SplineSeries
} from '@devexpress/dx-react-chart-material-ui';
import { RedLinearProgress, GreenLinearProgress, BlueLinearProgress } from './progressBars';


const FeaturedProfileCard = props => {
  const { selectUserProfile, userProfile, history } = props;
  const handleClick = e => {
    selectUserProfile(userProfile);
    history.push(ROUTES.USERPROFILE);
  };

  const nestData = userProfile.rep ? userProfile.rep.historical.filter(item => item.tag === 'Nest').map(e => (
    { argumentNest: e.arg, valueNest: e.amount }
  )) : [];
  const saberData = userProfile.rep ? userProfile.rep.historical.filter(item => item.tag === 'Saber').map(e => (
    { argumentSaber: e.arg, valueSaber: e.amount }
  )): [];
  const fragmentData = userProfile.rep ? userProfile.rep.historical.filter(item => item.tag === 'Fragment').map(e => (
    { argumentFragment: e.arg, valueFragment: e.amount }
  )) : [];
  const chartData = nestData.concat(saberData).concat(fragmentData);

  return (
    
    <Card>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Container justify='center'>
                <Avatar
                  alt='Profile Image'
                  src={userProfile.avatar}
                  style={{ textAlign: 'center', width: '50%', height: '50%' }}
                />
                <Typography gutterBottom variant='h5' component='h2'>
                  {`${userProfile.name.first} ${userProfile.name.last}`}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {userProfile.company}
                </Typography>
                {userProfile.rep.current.map((tag, index) => (
                  <>
                    <Typography variant='body2' component='p'>
                      {`#${tag.tag} ${tag.amount}`}
                    </Typography>
                    {index === 0 ? (
                      <RedLinearProgress
                        variant='determinate'
                        value={tag.amount}
                      />
                    ) : index === 1 ? (
                      <GreenLinearProgress
                        variant='determinate'
                        value={tag.amount}
                      />
                    ) : (
                      <BlueLinearProgress
                        variant='determinate'
                        value={tag.amount}
                      />
                    )}
                  </>
                ))}
              </Container>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Chart
                  style={{ maxHeight: '20rem' }}
                  data={chartData}
                >
                  <ArgumentAxis showGrid />
                  <ValueAxis />
                  <SplineSeries
                    name='#Nest'
                    valueField='valueNest'
                    argumentField='argumentNest'
                  />
                  <SplineSeries
                    name='#Saber'
                    valueField='valueSaber'
                    argumentField='argumentSaber'
                  />
                  <SplineSeries
                    name='#Fragment'
                    valueField='valueFragment'
                    argumentField='argumentFragment'
                  />
                </Chart>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default FeaturedProfileCard;
