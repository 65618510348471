import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import buildStyles from "../components/WidgetManager/styles";
import EditWidget from "../components/WidgetManager/editWidget";
import NewWidget from "../components/WidgetManager/newWidget";
import WidgetGrid from "../components/WidgetManager/widgetGrid";
import * as ROLES from "../constants/roles";
import { withAuthorization } from "../components/Session";
import Heading from "../components/WidgetManager/heading";
import { withFirebase } from "../components/Firebase";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../store/actions/widgetManager";

import * as Settings from "../constants/settings";

const mapStateToProps = state => ({
  isNewOpen: state.widgetManager.isNewOpen,
  isEditOpen: state.widgetManager.isEditOpen,
  selectedRow: state.widgetManager.selectedRow,
  error: state.widgetManager.error,
  title: Settings.APPNAME,
  schema: state.widgetManager.schema,
  values: state.widgetManager.values,
  shortName: Settings.APPSHORTNAME,
  appIcon: Settings.APPICON,
  authUserRoles: state.authUser.roles
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const styles = theme => buildStyles(theme);

class widgetManager extends React.Component {
  render() {
    const { actions } = this.props;

    return (
      <React.Fragment>
        <Heading
          classes={this.props.classes}
          handleNewOpen={() => {
            actions.widgetManagerNewToggle(true);
          }}
          handleNewClose={() => {
            actions.widgetManagerNewToggle(false);
          }}
          isNewOpen={this.props.isNewOpen}
          title={this.props.title}
          name={this.props.shortName}
          icon={this.props.appIcon}
        />
        <WidgetGrid
          handleEditOpen={row => {
            actions.widgetManagerSelectedRow(row);
            actions.widgetManagerEditToggle(true);
          }}
          handleEditClose={() => {
            actions.widgetManagerEditToggle(false);
          }}
          isEditOpen={this.props.isEditOpen}
          handleNewOpen={() => {
            actions.widgetManagerNewToggle(true);
          }}
          handleNewClose={() => {
            actions.widgetManagerNewToggle(false);
          }}
          isNewOpen={this.props.isNewOpen}
          hiddenColumnNames={Settings.defaultHiddenCols.ADMIN}
        />
        <EditWidget
          roles={this.props.authUserRoles}
          classes={this.props.classes}
          open={this.props.isEditOpen}
          close={() => {
            actions.widgetManagerEditToggle(false);
          }}
          selectedRow={this.props.selectedRow}
          firebase={this.props.firebase}
          schema={this.props.schema}
          handleDelete={e => {
            const { firebase } = this.props;
            firebase
              .widget(this.props.selectedRow.uid)
              .remove()
              .then(() => {
                window.location.reload();
              });
          }}
          handleUpdate={e => {
            const { values, firebase } = this.props;
            firebase
              .widget(this.props.selectedRow.uid)
              .set(values)
              .then(() => {
                window.location.reload();
              })
              .catch(error => {
                console.log(error);
              });
          }}
          handleChange={e => {
            actions.widgetManagerHandleChange(e);
          }}
        />
        <NewWidget
          roles={this.props.authUserRoles}
          classes={this.props.classes}
          open={this.props.isNewOpen}
          close={() => {
            actions.widgetManagerNewToggle(false);
          }}
          schema={this.props.schema}
          firebase={this.props.firebase}
          handleCreate={e => {
            const { firebase, values } = this.props;
            firebase
              .widgets()
              .push(values)
              .then(() => {
                window.location.reload();
              })
              .catch(error => {
                console.log(error);
              });
          }}
          handleChange={e => {
            actions.widgetManagerHandleChange(e);
          }}
        />
      </React.Fragment>
    );
  }
}

const condition = authUser => authUser && !!authUser.roles[ROLES.USER];
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    withFirebase(withRouter(withAuthorization(condition)(widgetManager)))
  )
);
