export const WIDGET_MANAGER_NEW_TOGGLE = "WIDGET_MANAGER_NEW_TOGGLE";
export const WIDGET_MANAGER_EDIT_TOGGLE = "WIDGET_MANAGER_EDIT_TOGGLE";
export const WIDGET_MANAGER_SELECTED_ROW = "WIDGET_MANAGER_SELECTED_ROW";
export const WIDGET_MANAGER_HANDLE_CHANGE = "WIDGET_MANAGER_HANDLE_CHANGE";
export const WIDGET_MANAGER_HANDLE_CREATE = "WIDGET_MANAGER_HANDLE_CREATE"
export const WIDGET_MANAGER_HANDLE_DELETE = "WIDGET_MANAGER_HANDLE_DELETE"
export const WIDGET_MANAGER_HANDLE_UPDATE = "WIDGET_MANAGER_HANDLE_UPDATE"

export function widgetManagerNewToggle(bool) {
  return { type: WIDGET_MANAGER_NEW_TOGGLE, bool };
}

export function widgetManagerEditToggle(bool) {
  return { type: WIDGET_MANAGER_EDIT_TOGGLE, bool };
}

export function widgetManagerSelectedRow(row) {
  return { type: WIDGET_MANAGER_SELECTED_ROW, row };
}

export function widgetManagerHandleChange(e) {
  return { type: WIDGET_MANAGER_HANDLE_CHANGE, e };
}

export function widgetManagerHandleCreate(e){
    return { type: WIDGET_MANAGER_HANDLE_CREATE}
}

export function widgetManagerHandleDelete(e){
    return { type: WIDGET_MANAGER_HANDLE_DELETE}
}

export function widgetManagerHandleUpdate(e){
    return { type: WIDGET_MANAGER_HANDLE_UPDATE}
}
