import { createMuiTheme } from '@material-ui/core/styles'
// import orange from '@material-ui/core/colors/orange'
// import teal from '@material-ui/core/colors/teal'
import { blueGrey, indigo } from '@material-ui/core/colors';
// import blue from '@material-ui/core/colors/blue'


export const coreStyles = theme => ({
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
  },
  logoWrapper: {
    flexGrow: 1,
  },
  logoButton: {
    '&:hover': {
      backgroundColor: "transparent"
    }
  },
  sidebarLogoBox: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sidebarLogo: {
    '&:hover': {
      backgroundColor: "transparent"
    },
  },
  sidebarLogoImg: {
    width: '150px',
    padding: '20px',
  },
  listText: {
    fontSize: ".8rem",
    textTransform: "uppercase",
  },
  nested: {
    paddingLeft: '90px',
  },
  paper: {
    position: 'absolute',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme
      .spacing.unit * 3}px`,
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, -15%)',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  newButtonGrid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    justifyContent: "flex-vertical",
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  editorWrapper: {
    backgroundColor: "white",
    color: "black",
  },
  editorClass: {
    minHeight: '100%'
  },
  editorCard: {
    minHeight: '100%'
  },
  editorToolbar: {
    minHeight: '100%'
  },
})

export const coreTheme = {
  palette: {
    // primary: orange,
    // secondary: teal,
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Montserrat, "Open Sans", Helvetica, Arial, sans-serif',
  },

  // newButtonGrid: {
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  //   textAlign: 'center',
  //   justifyContent: "flex-vertical",
  // [theme.breakpoints.up('sm')]: {
  //   textAlign: 'right',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   width: '80%',
  // },
  // },
  // sidebarButton: {
  //   textAlign: 'center',
  //   marginLeft: 'auto',
  //   marginRIght: 'auto',
  //   padding: '20px'
  // },

  // menuButton: {
  //   backgroundColor: 'transparent'
  // },



}

export const imperianTheme = createMuiTheme({
  ...coreTheme,
  palette: {
    ...coreTheme.palette,
    type: 'dark',
  }
})

export const monarchTheme = createMuiTheme({
  ...coreTheme,
  typography: {
    ...coreTheme.typography,
    fontColor: "#333333"
  }
})

export const lightPieTheme = createMuiTheme({
  ...coreTheme,
  palette: {
       primary: {
         light: indigo[100],
         main: indigo[300],
         dark: indigo[500],
       },
       secondary: {
         light: blueGrey[300],
         main: blueGrey[500],
         dark: blueGrey[700],
       }
  },
})

export const darkPieTheme = createMuiTheme({
  ...coreTheme,
  palette: {
       primary: {
         light: indigo[100],
         main: indigo[300],
         dark: indigo[500],
       },
       secondary: {
         light: blueGrey[300],
         main: blueGrey[500],
         dark: blueGrey[700],
       },
       type: 'dark',
  },
})

