import React from "react";
import { Modal } from "@material-ui/core";
import { Card, CardContent, Grid } from "@material-ui/core";
import { TextBox, Check, Picker, FormButton, DeleteButton } from "../FormFields";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import * as Settings from "../../constants/settings";
import Button from "@material-ui/core/Button";

export const NewTextBox = props => {
  return props.schema.map(function(item, key) {
    if (item.step === props.activeStep) {
      if (
        item.roles.some(role => {
          return props.roles[role];
        })
      ) {
        switch (item.type) {
          case "textbox":
            return (
              <TextBox
                key={key}
                fieldLabel={item.title}
                fieldName={item.name}
                handleChange={props.handleChange}
                defaultValue={props.defaultValue[item.name]}
              />
            );
          case "bool":
            return (
              <Check
                key={key}
                label={item.title}
                fieldName={item.name}
                // handleClick={props.handleChange}
              />
            );
          case "date":
            return (
              <Picker
                key={key}
                label={item.title}
                fieldName={item.name}
                // handleClick={props.handleChange}
              />
            );
          case "image":
            return (
              <img alt="logo" src={item.logo} />
            );
          default:
            return true;
        }
      } else {
        return true
      }
    } else {
      return true;
    }
  });
};

function EditWidget(props) {
  const { classes } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }
  return (
    <Modal open={props.open} onClose={props.close}>
      <div>
        <main className={classes.main}>
          <Card className={classes.paper}>
            <CardContent className={classes.form}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {Settings.steps.map((label, index) => {
                  if (
                    label.roles.some(role => {
                      return props.roles[role];
                    })
                  ) {
                    return (
                      <Step key={index}>
                        <StepLabel>{label.title}</StepLabel>
                        <StepContent>
                          <NewTextBox
                            schema={props.schema}
                            handleChange={props.handleChange}
                            defaultValue={props.selectedRow}
                            activeStep={index}
                            roles={props.roles}
                          />
                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Back
                              </Button>
                              {activeStep === Settings.steps.length - 1 ? (
                                <></>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleNext}
                                  className={classes.submit}
                                >
                                  Next
                                </Button>
                              )}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    );
                  } else {
                    return true;
                  }
                })}
              </Stepper>
              {/* 
                <br /><br />
                <MediaPlayer media={this.props.selectedRow.widgetfile}/> */}
              <br />
              <br />
              <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item>
                  <FormButton
                    label="Update"
                    handleSubmit={props.handleUpdate}
                  />
                </Grid>
                <Grid item>
                  <DeleteButton
                    buttonLabel="Delete"
                    dialogContentText="You are about to permanantly delete this item. Are you sure?"
                    dialogTitle="Delete Confirmation"
                    handleDelete={props.handleDelete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </main>
      </div>
    </Modal>
  );
}

export default EditWidget;
