export const CATALOG_CURRENCIES = "CATALOG_CURRENCIES";
export const CATALOG_PROJECTS = "CATALOG_PROJECTS";
export const CATALOG_SELECTED_CURRENCIES = "CATALOG_SELECTED_CURRENCIES";

export function catalogCurrencies(currencies) {
  return { type: CATALOG_CURRENCIES, currencies };
}

export function catalogProjects(projects) {
  return { type: CATALOG_PROJECTS, projects };
}

export function catalogSelectedCurrencies(currencies){
  return { type: CATALOG_SELECTED_CURRENCIES, currencies };
}