export const AUTH_USER_SET_ROLES = 'AUTH_USER_SET_ROLES'
export const AUTH_USER_SET_DISPLAY_NAME = 'AUTH_USER_SET_DISPLAY_NAME'
export const AUTH_USER_SET_EMAIL = 'AUTH_USER_SET_EMAIL'

export function authUserSetRoles(roles) {
    return { type: AUTH_USER_SET_ROLES, roles }
}

export function authUserSetDisplayName(value) {
    return { type: AUTH_USER_SET_DISPLAY_NAME, value }
}

export function authUserSetEmail(value) {
    return { type: AUTH_USER_SET_EMAIL, value }
}