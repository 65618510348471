import React from "react";
import { Grid } from "@material-ui/core";
import DxGrid from "../DxGrid";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { compose } from "recompose";
import * as ROLES from "../../constants/roles";
import CircularProgress from '@material-ui/core/CircularProgress';


class WidgetGrid extends React.Component {
  state = {
    isLoading: true,
    data: [],
    error: null,
    columns: [],
    index: [],
    users: []
  };

  componentDidMount() {
    this.index();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  columns(data) {
    let columns = [
    ];
    for (var k in data[0]) {
        let y = k
        .replace(/([A-Z])/g, " $1")
        .replace(/([A-Z][a-z])/g, " $1")
        .replace(/_/g, " ");
      let z = y.charAt(0).toUpperCase() + y.slice(1);
      let x = { name: k, title: z };
      columns.push(x);
    }
    this.setState({
      columns,
      isLoading: false
    });
  }

  index() {
    this.props.firebase.users().on("value", snapshot => {
      const usersObject = snapshot.val();
      const columnsList = Object.keys(usersObject).map(key => ({
        username: usersObject[key].username,
        email: usersObject[key].email,
        
      }));
      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key
      }));
      this.setState({
        users: usersList,
        isLoading: true,
        index: usersList
      });
      this.columns(columnsList);
    });
  }

  render() {
    return (
      <React.Fragment>
        <br />
        <br />
        <Grid container justify='center'>
          <Grid item xs={11} sm={10}>
            {!this.state.isLoading ? (
              <DxGrid
                classes={this.props.classes}
                columns={this.state.columns}
                rows={this.state.index}
                handleEditOpen={this.props.handleEditOpen}
                handleEditClose={this.props.handleEditClose}
                isEditOpen={this.props.isEditOpen}
                handleNewOpen={this.props.handleNewOpen}
                handleNewClose={this.props.handleNewClose}
                isNewOpen={this.props.isNewOpen}
                title={this.props.title}
                name={this.props.name}
                icon={this.props.icon}
              />
            ) : (
              <div>
              <CircularProgress />
            </div>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withAuthorization(condition)(withFirebase(WidgetGrid)));
