import React from 'react';
import {
  Button,
  Menu,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import Portrait from '@material-ui/icons/PersonOutline'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import { withFirebase } from "../Firebase";
import * as ROUTES from '../../constants/routes';


const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleProfile() {
    setAnchorEl(null);
    props.history.push(ROUTES.USERPROFILE);
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {props.displayName}&nbsp;&nbsp;<Portrait />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem button onClick={handleProfile}>
            <ListItemIcon>
              <Portrait />
            </ListItemIcon>
            <ListItemText>
              <Typography className={props.classes.listText}>My Account</Typography>
            </ListItemText>
          </ListItem>

          <ListItem button onClick={props.clearReduxAuth}>
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <ListItemText>
              <Typography className={props.classes.listText}>Sign Out</Typography>
            </ListItemText>
          </ListItem>
      </Menu>
    </div>
  );
}

export default withFirebase(UserMenu)