import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import * as Actions from "../store/actions/sidebar"
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

const SideMenuButton = ({ actions }) => {
    return (
        <div>
            <IconButton
                style={{ backgroundColor: "transparent" }}
                className='menuButton'
                aria-label='Menu'
                onClick={e => {
                    actions.sidebarToggle(true)
                }}
            >
                <MenuIcon />
            </IconButton>
        </div>

    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(false, mapDispatchToProps)(SideMenuButton)