import {
    SIDEBAR_TOGGLE,
    SIDEBAR_THEMES_TOGGLE,
    SIDEBAR_SETTINGS_TOGGLE,
    SIDEBAR_THEMES_SWITCH
} from '../actions/sidebar'
import { lightPieTheme, darkPieTheme } from '../../components/App/styles'
import ImperianIcon from "../../Icons/pie-transparent.png";
import MonarchIcon from "../../Icons/pie-transparent.png"


function sidebar(state = {}, action) {
    switch (action.type) {
        case SIDEBAR_TOGGLE:
            return {
                ...state,
                isOpen: action.bool
            }
        case SIDEBAR_THEMES_TOGGLE:
            return {
                ...state,
                isThemesOpen: action.bool
            }
        case SIDEBAR_SETTINGS_TOGGLE:
            return {
                ...state,
                isSettingsOpen: action.bool
            }
        case SIDEBAR_THEMES_SWITCH:
                const currentTheme = () => {
                    if(action.theme === 'lightPieTheme'){
                      return lightPieTheme
                    } else {
                      return darkPieTheme
                    }
                  }
                  const currentIcon = () => {
                    if(action.theme === 'imperian'){
                      return ImperianIcon
                    } else {
                      return MonarchIcon
                    }
                  }
                  return {
                      ...state,
                      theme: currentTheme(),
                      themeIcon: currentIcon()
                  }
        default:
            return state;
    }
}

export default sidebar