import {
  WIDGET_MANAGER_NEW_TOGGLE,
  WIDGET_MANAGER_EDIT_TOGGLE,
  WIDGET_MANAGER_SELECTED_ROW,
  WIDGET_MANAGER_HANDLE_CHANGE,
  WIDGET_MANAGER_HANDLE_CREATE,
  WIDGET_MANAGER_HANDLE_DELETE,
  WIDGET_MANAGER_HANDLE_UPDATE
} from "../actions/widgetManager";
// import { create } from '../../components/WidgetManager/createWidget'

function widgetManager(state = {}, action) {
  switch (action.type) {
    case WIDGET_MANAGER_NEW_TOGGLE:
      return {
        ...state,
        isNewOpen: action.bool
      };
    case WIDGET_MANAGER_EDIT_TOGGLE:
      return {
        ...state,
        isEditOpen: action.bool
      };
    case WIDGET_MANAGER_SELECTED_ROW:
      return {
        ...state,
        selectedRow: action.row,
        uid: action.row.uid,
        values: action.row
      };
    case WIDGET_MANAGER_HANDLE_CHANGE:
      const { name, value } = action.e.target;
      let valueObject = state.values;
      valueObject[name] = value;
      return {
        ...state,
        values: valueObject
      };

    case WIDGET_MANAGER_HANDLE_CREATE:
      // create(valueArray);
      return {
        ...state
      };
    case WIDGET_MANAGER_HANDLE_DELETE:
      return {
        ...state
      };
    case WIDGET_MANAGER_HANDLE_UPDATE:
      return {
        ...state
      };
    default:
      return state;
  }
}

export default widgetManager;
