import * as React from "react";
import {
  Paper
  // Button,
  // Grid as UIGrid,
  // Tooltip
} from "@material-ui/core";
// import { withRouter } from "react-router-dom";
// import PropTypes from "prop-types";
// import ColorizeIcon from "@material-ui/icons/Colorize";
// import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// import withStyles from "@material-ui/core/styles/withStyles";
import {
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  EditingState,
  FilteringState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  SearchPanel,
  PagingPanel,
  Toolbar,
  TableFixedColumns,
  ColumnChooser,
  TableColumnVisibility,
  TableColumnResizing,
  TableEditRow,
  TableFilterRow
} from "@devexpress/dx-react-grid-material-ui";
class DxGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: this.props.columns,
      rows: [this.props.rows],
      data: this.props.rows,
      hiddenColumnNames: this.props.hiddenColumnNames,
      leftColumns: ["Actions"],
      defaultColumnWidths: this.props.defaultColumnWidths,
      editState: false,
      modeState: false
    };

    this.changeSelection = selection => this.setState({ selection });
  }

  hiddenColumnNamesChange = hiddenColumnNames => {
    this.setState({ hiddenColumnNames });
  };

  commitChanges = ({ added, changed, deleted }) => {
  };

  changeEditState(event) {
    this.setState({ editState: !this.state.editState });
  }

  modeToggle(event) {
    if (this.state.modeState) {
      this.setState({
        hiddenColumnNames: [
          // "Ba","Ca","Fe","Mn","K","Li","Mg","Na","P","Pb","Sr","Zn"
        ]
      });
    } else {
      this.setState({
        hiddenColumnNames: [
          // "SampleDate", "Company", "Well", "Employee"
        ]
      });
    }
    this.setState({ modeState: !this.state.modeState });
  }

  Cell = props => {
    if (this.state.editState) {
      const {
        row,
        ...other
      } = props;
      return <TableEditRow.Cell {...other} />;
    } else {
      const {
        row,
        ...other
      } = props;
      return <Table.Cell {...other} />;
    }


    // const { column } = props;
    // if (column.name === "Actions") {
    //   return (
    //     <Table.Cell>
    //       <UIGrid container justify='flex-start'>
    //         <UIGrid item>
    //           <Tooltip title='Add Lab Analysis'>
    //             <Button
    //               size='small'
    //               onClick={() => {
    //                 this.props.history.push("/lab");
    //               }}>
    //               <ColorizeIcon />
    //             </Button>
    //           </Tooltip>
    //           <Tooltip title='Approve'>
    //             <Button
    //               size='small'
    //               onClick={() => {
    //                 this.props.history.push("/approve");
    //               }}>
    //               <CloudUploadIcon />
    //             </Button>
    //           </Tooltip>
    //         </UIGrid>
    //       </UIGrid>
    //     </Table.Cell>
    //   )
    // } else if (column.name === "SampleId" ||
    // column.name === "SampleDate" ||
    // column.name === "Company" ||
    // column.name === "Well" ||
    // column.name === "Employee" ||
    // column.name === "Status"
    // ){
    //   return <Table.Cell {...props} />
    //  }
    //  if (this.state.editState){
    //   return <TableEditRow.Cell {...props} />;
    //  } else {
    //   return <Table.Cell {...props} />
    //  }
  };

  render() {
    const {
      columns,
      tableColumnExtensions,
      hiddenColumnNames,
      leftColumns
    } = this.state;

    const TableRow = ({ 
      row,
      ...restProps }) => (
      <Table.Row
        {...restProps}
        // eslint-disable-next-line no-alert
        onClick={() => {
          this.props.handleEditOpen(row);
        }}
      />
    );

    return (
      <Paper>

        <Grid rows={this.state.data} columns={columns}>
          <SearchState />
          <FilteringState />
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <SortingState
            defaultSorting={[{ columnName: "Name", direction: "asc" }]}
          />
          <EditingState onCommitChanges={this.commitChanges} />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <Table
            cellComponent={this.Cell}
            columnExtensions={tableColumnExtensions}
            rowComponent={TableRow}
          />
          <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
          />
          <TableFixedColumns leftColumns={leftColumns} />
          <Toolbar />
          {/* <EditToggle
            buttonClick={this.changeEditState.bind(this)}
            modeToggle={this.modeToggle.bind(this)}
          /> */}
          <SearchPanel />
          <ColumnChooser />
          <PagingPanel />
        </Grid>
      </Paper>
    );
  }
}

// DxGrid.propTypes = {
//   // history: PropTypes.object.isRequired,
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(styles)(withRouter(DxGrid));
export default DxGrid;
