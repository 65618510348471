const buildStyles = theme => ({
  paper: {
    position: 'absolute',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme
      .spacing.unit * 3}px`,
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, -15%)',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  responsive: {
    width: "100%",
    height: "auto",
  },
});

export default buildStyles;
