import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import buildStyles from "./styles";

import Header from "./Header";
import Banner from "./Banner";
import Features from "./Features";
import Content from "./Content";
import Footer from "./Footer";
import bannerImage from "./blockchain-technology-concept-xl.png";
import contentImage from "./software-img-3.png";
import PieIcon from "../../Icons/pie-transparent.png";

import { SIGN_IN, LANDING } from '../../constants/routes'

const styles = theme => buildStyles(theme);

class Landing extends React.Component {

    render() {
        const { classes, history } = this.props

        const bannerTitle = "Ut enim ad minima veniam";
        const bannerCopy =
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.";
        const contentTitle = "Reiciendis Voluptatibus";
        const contentCopy =
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        const featureArray = [
            {
                title: "quis nostrum",
                copy: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores ",
                icon: "WebOutlined"
            },
            {
                title: "exercitationem",
                copy: "et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi",
                icon: "VerifiedUserOutlined"
            },
            {
                title: "voluptate velit",
                copy: "laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio",
                icon: "BuildOutlined"
            },
            {
                title: "nulla pariatur",
                copy:
                    "Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime",
                icon: "LayersOutlined"
            }
        ];
        const footerContent = "Copyright 2014, All Rights Reserved"

        return (
            <React.Fragment>
                <Header
                homeRoute={LANDING}
                loginRoute={SIGN_IN}
                icon={PieIcon}
                classes={classes}
                history={history}
                />
                <Banner
                    title={bannerTitle}
                    copy={bannerCopy}
                    image={bannerImage}
                    classes={classes}
                />
                <Features featureArray={featureArray} classes={classes} />
                <Content
                    title={contentTitle}
                    copy={contentCopy}
                    image={contentImage}
                    classes={classes}
                />
                <Footer 
                    icon={PieIcon}
                    classes={classes}
                    footerContent={footerContent}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(Landing));
