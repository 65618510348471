import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withStyles from "@material-ui/core/styles/withStyles";
import ImperianIcon from "../../Icons/pie-transparent.png";
import buildStyles from "../SignIn/styles";
import { Card, CardContent, Grid } from "@material-ui/core";
import { TextBox, FormButton } from "../FormFields";
const styles = theme => buildStyles(theme);

const INITIAL_STATE = {
  email: '',
  error: null,
  emailSent: false,
};

const EmailSentMessage = () => {
  return (
    <div>
      A link has been sent to your email to reset your password.
    </div>
  )
}

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE, emailSent: true });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <Card className={classes.paper}>
          <CardContent className={classes.form}>
            <img
              src={ImperianIcon}
              alt='Imperian Logo'
              className={classes.responsive}
            />
            {error && <p>{error.message}</p>}
            {this.state.emailSent ?
              <React.Fragment>
                <EmailSentMessage />
              </React.Fragment>
              :
              <React.Fragment>

                <TextBox
                  fieldName='email'
                  fieldLabel='Email'
                  handleChange={this.onChange}
                  value={email}
                />
                <Grid container alignItems='center' justify='center'>
                  <Grid item>
                    <FormButton
                      label='Reset Password'
                      handleSubmit={this.onSubmit}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </CardContent>
        </Card>
      </main>
      /* <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <button disabled={isInvalid} type="submit">
          Reset My Password
        </button>

        {error && <p>{error.message}</p>}
      </form> */
    );
  }
}

const PasswordForgetLink = () => (
  <div>
    <Link style={{
      textDecoration: 'none',
      // color: "#009688"
      color: "#7986cb"
    }} to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </div>
);

export default withStyles(styles)(withRouter(withFirebase(PasswordForgetForm)));


export { PasswordForgetLink };