import React from "react";
import { withRouter } from "react-router-dom";
// import withStyles from "@material-ui/core/styles/withStyles";
// import buildStyles from "./styles";
import CreatableSelect from 'react-select';

// const styles = theme => buildStyles(theme);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'orange' : 'teal',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    }
  }

class MySelect extends React.Component {
    render() {
        // const { classes } = this.props;

        return (
            <CreatableSelect
                styles={customStyles}
                isClearable
                isSearchable
                value={this.props.selectedRoles}
                onChange={this.props.handleSelect}
                onCreateOption={this.props.handleCreate}
                options={this.props.options}
                id={this.props.name}
                name={this.props.name}
                className='selectContainer'
                classNamePrefix="select"
                placeholder={this.props.placeholder}
                isMulti={this.props.isMulti}
            />
        );
    }
}

export default (withRouter(MySelect));