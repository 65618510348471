import React from "react";
import { Grid, Typography } from "@material-ui/core";

class Footer extends React.Component {
 
  render() {
    const { classes, footerContent } = this.props;
    const Icon = this.props.icon;

    return (
        <div className={classes.layout}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.footerContent}>
                <img
                  src={Icon}
                  alt='Logo'
                  width='400'
                  center='true'
                  className={classes.responsive}
                />
                <Typography variant='caption' color='inherit' paragraph>
                  {footerContent}{" "}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
    );
  }
}

export default Footer;
